import React from 'react'
// import { Button } from '@progress/kendo-react-buttons';
// import { Loader } from '@progress/kendo-react-indicators';
import { CircularProgress } from '@mui/material';
import {Button} from '@mui/material';


export default function SubmitableLoadingButton({loading, primary=true, type='submit', children, disabled=false}){


    // const loadingType = ['pulsing','infinite-spinner','converging-spinner'][0];
    var color = primary ? "primary" : "secondary";


    return (
        <div className="loader-white">
            <Button
                color={color}
                variant="contained"
                disabled={loading || disabled}
                type={type}
            >
            {children} { loading && <>&nbsp;<CircularProgress/> </>}    
            </Button>
        {/* <Button
            primary={primary && true}
            type={type}
            disabled={loading || disabled}>
            {children} { loading && <>&nbsp;<Loader type={loadingType} themeColor={'primary'}/></>}
        </Button> */}
        </div>
    )

}