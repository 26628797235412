import React from 'react';

import { useLocation, useNavigate } from 'react-router-dom';
import { Drawer, DrawerContent, DrawerItem } from '@progress/kendo-react-layout';
import {CONSTANTS} from '../constants';
import { Header } from './Header.js';

import {
    Dashboard as DashboardIcon,
    Flight as PairingIcon,
    RoomService as HotelIcon,
    Commute as TransportIcon,
    MailOutline as SubscriptionIcon,
    LocalHotel as ReservationsIcon,
    People as CrewIcon,
    Warning as OutageIcon,
    EventAvailable as BidPeriodIcon,
    DirectionsWalk as WalkingTimeIcon,
    Settings as SettingsIcon,
} from '@mui/icons-material';
// } from '@material-ui/icons';

import { AppContext } from '../AppContext';

const DrawerIconWrapper = ({Icon}) => {
    return <span style={{marginLeft:5, marginRight:5}}><Icon/></span>

}

const items = [
    { name: 'Dashboard', icon:DashboardIcon, selected: true , route: '/' },
    { name: 'Pairings', icon: PairingIcon, route: '/pairings' },
    { name: 'Bid Periods', icon: BidPeriodIcon, route: '/bid-periods' },
    { name: 'Walking Times', icon: WalkingTimeIcon, route: '/walking-times' },
    { name: 'Crews', icon: CrewIcon, route: '/crews' },
    { name: 'Reservations', icon: ReservationsIcon, route: '/reservations' },
    { name: 'Hotels', icon: HotelIcon, route: '/hotels' },
    { name: 'Transports', icon: TransportIcon, route: '/transports' },
    { name: 'Subscriptions', icon: SubscriptionIcon, route: '/subscriptions' },
    { name: 'Outages', icon:OutageIcon, route:'/outages'},
    { name: 'General Settings', icon:SettingsIcon, route:'/general-settings'},
    // { name: 'profile', iconSvg: 'profile-icon', route: '/profile' },
    // { separator: true },
    // { name: 'info', iconSvg: 'info-icon', route: '/info' }
];



const CustomDrawerItem = (props) => {
    const {  text, icon, ...others } = props;
    // var Icon = icon;
    return (
        <DrawerItem {...others}>
            {/* <Icon/> */}
            <DrawerIconWrapper Icon={icon} />
            <span className="k-item-text">{text}</span>
        </DrawerItem>
    );
};

class DrawerRouterContainerAux extends React.Component {
    state = {
        expanded: true,
        selectedId: items.findIndex(x => x.selected === true),
        isSmallerScreen: window.innerWidth < 768
    }

    componentDidMount() {
        window.addEventListener('resize', this.resizeWindow)
        this.resizeWindow()
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resizeWindow)
    }

    resizeWindow = () => {
        this.setState({ isSmallerScreen: window.innerWidth < 768 })
    }

    handleClick = () => {
        this.setState((e) => ({expanded: !e.expanded}));
    }


    handleSelect = (e) => {
        this.setState({selectedId: e.itemIndex/*, expanded: false*/});
        this.props.navigate(e.itemTarget.props.route, { replace: false });
    }

    getSelectedItem = (pathName) => {
        let currentPath = items.find(item => item.route === pathName);
        if (currentPath.name) {
            return currentPath.name;
        }
    }
    render() {
        let selected = this.getSelectedItem(this.props.location.pathname);
        // const localizationService = provideLocalizationService(this);


        var availableMenuItems = items;
        if(this.props.airlineCode && CONSTANTS.SECTIONS_BY_AIRLINE[this.props.airlineCode])
        {
            availableMenuItems = items.filter(item => CONSTANTS.SECTIONS_BY_AIRLINE[this.props.airlineCode].indexOf(item.name) !== -1);
        }

        return (
             <React.Fragment>
                <Header
                    onButtonClick={this.handleClick}
                    page={selected}
                    // page={localizationService.toLanguageString(`custom.${selected}`)}
                />
                <Drawer
                    expanded={this.state.expanded}
                    items={availableMenuItems.map((item) => ({
                                ...item,
                                // text: localizationService.toLanguageString(`custom.${item.name}`),
                                text:item.name,
                                selected: item.name === selected,
                                icon:item.icon
                            }))
                    }
                    item={CustomDrawerItem}
                    position='start'
                    mode={this.state.isSmallerScreen ? 'overlay' : 'push'}
                    mini={this.state.isSmallerScreen ? false : true}

                    onOverlayClick={this.handleClick}
                    onSelect={this.handleSelect}
                >
                    <DrawerContent>
                        {this.props.children}
                    </DrawerContent>
                </Drawer>
        </React.Fragment>
        );
    }
};

// registerForLocalization(DrawerRouterContainer);

export default function DrawerRouterContainer(props){
    const location = useLocation();
    const navigate = useNavigate();
    const {  airlineCode } = React.useContext(AppContext);
    return <DrawerRouterContainerAux location={location}  navigate={navigate} airlineCode={airlineCode}>{props.children}</DrawerRouterContainerAux>
}

// export default withRouter(DrawerRouterContainer);

