import * as React from 'react';
import { NumberIndicator } from './NumberIndicator';


const numberIndicatorStyle = {
    container:{
        display: 'flex' 
    },
    
}

export const NumberIndicatorGroup = ({data, vertical=false, wrap=false}) => {
    


    return (
        <div style={{...numberIndicatorStyle.container, 
            flexDirection: vertical ? 'column' : 'row',
            flexWrap: wrap ? 'wrap' : 'nowrap'
            }}>
            { Array.isArray(data) && data.map((indicatorData, index) =>(
                <NumberIndicator key={index}
                    {...indicatorData}     
             />
            ) )}
        </div>
    );
}



