import React from 'react'
// import { Button } from '@progress/kendo-react-buttons';
import { CircularProgress } from '@mui/material';
import {Button} from '@mui/material';


export default function LoadingButton({onClick, primary=true, type='submit', children, icon='', disabled=false, size=24, hideContentOnLoading=false}){

    const [loading, setLoading] = React.useState(false);

    const onClickHandler = async (e) => {
        if(typeof(onClick) === "function"){
            try{
                setLoading(true)
                if(onClick.constructor.name === "Function"){
                    onClick(e)
                }else if(onClick.constructor.name === "AsyncFunction"){
                    await onClick(e);
                }
                setLoading(false)
            }catch(e){
                setLoading(false)
                throw e;
            }
        }
    }
    var color = primary ? "primary" : "secondary";
    // const loadingType = ['pulsing','infinite-spinner','converging-spinner'][0];
    return (
        <div className="loader-white">
            <Button
                variant="contained"
                color={color}
                onClick={onClickHandler}
                startIcon={icon}
                disabled={loading || disabled}
                >
                { (!loading || !hideContentOnLoading ) && children } 
                { (loading && !hideContentOnLoading ) && <>&nbsp;</> } 
                { loading && <CircularProgress size={size} /> }
            </Button>
        {/* <Button
            primary={primary && true}
            type={type}
            onClick={onClickHandler}
            disabled={loading && disabled}
            icon={icon}
            >
            
            { (!loading || !hideContentOnLoading ) && children } 
            { (loading && !hideContentOnLoading ) && <>&nbsp;</> } 
            { loading && <CircularProgress size={size} /> }
            {/* { loading && <>&nbsp;<Loader type={loadingType} themeColor={'primary'}/></>} */}
        {/* </Button> */} 
        
        </div>
    )

}