import * as React from 'react';
import { QueuesPanel } from '../Queues/QueuesPanel';


export const BookingWizardMonitor = (props) => {
    var queues = [
        {
            title:'Sync',
            code:'booking-wizard-store'
        }
    ]
    return (
        <QueuesPanel
            title={'Booking Wizard Queue'}
            queues={queues}
        />
    )
}