
import * as React from 'react';

import { InternationalWalkingTimesForm } from '../components/WalkingTimes/InternationalWalkingTimesForm';
import { AirlineFilter } from '../components/AirlineFilter';
import { DomesticWalkingTimesForm } from '../components/WalkingTimes/DomesticWalkingTimesForm';
import { DefaultWalkingTimesForm } from '../components/WalkingTimes/DefaultWalkingTimesForm';
import { Navigate } from 'react-router-dom';

const WalkingTimesPage = () => {


    return (
        <div id="WalkingTimesPage" className="crews-page main-content">
            <AirlineFilter rejected="JL">
                <Navigate replace to={{ pathname: "/", }} />
            </AirlineFilter>
            <AirlineFilter allowed={["JL"]}>
                <InternationalWalkingTimesForm />
                <DomesticWalkingTimesForm />
                <DefaultWalkingTimesForm />
            </AirlineFilter>
            
        </div>
    );
}

export default WalkingTimesPage;

