import * as React from 'react';
import { CircularProgress } from '@mui/material';
import { AppContext } from '../AppContext';
import { performHttpGetOperationOnMicrohub } from '../providers/MicrohubApiProvider';

const REFRESH_FREQUENCY_MS = 15000;

export function AsyncHttpMonitor(props) {
    const {
        refreshUrl,
        onComplete
    } = props;

    const [message, setMessage] = React.useState("Please wait");
    const { getIdToken } = React.useContext(AppContext);

    React.useEffect(() => {
        if (!refreshUrl) return;
        const interval = setInterval(async () => {
            try{
                var idToken = await getIdToken();
                var rawIdToken = idToken.idToken.rawIdToken;
                var response = await performHttpGetOperationOnMicrohub(refreshUrl, {}, rawIdToken);
                if (response.runtimeStatus === "Completed") {
                    if (typeof (onComplete) === "function") onComplete(response.output);
                }
                else if (response.runtimeStatus === "Running") {
                    console.log("Got message status: " + response.customStatus)
                    setMessage(response.customStatus);
                }
            }catch(e){
                console.log(e.message);
                if (response.runtimeStatus === "Completed") {
                    if (typeof (onComplete) === "function") onComplete(null, e);
                }
            }

        }, REFRESH_FREQUENCY_MS);
        return () => clearInterval(interval);
    }, [refreshUrl, getIdToken, onComplete]);

    if (!refreshUrl) {
        return <></>;
    }

    return (<>
        <div
            style={{marginTop:20,marginLeft:10, display:"inline-block"}}
        >{message}&nbsp;&nbsp;&nbsp;<CircularProgress size={15}/></div>
    </>
    );

}