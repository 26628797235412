
import * as React from 'react';
import { Navigate } from 'react-router-dom';
import { AirlineFilter } from '../components/AirlineFilter';
import { HandleHotelEventsToCrewManagerForm } from '../components/Hotels/HandleHotelEventsToCrewManagerForm';
import { HotelQueuesMonitor } from '../components/Hotels/HotelQueuesMonitor';
import { SyncHotelForm } from '../components/Hotels/SyncHotelForm';


const HotelsPage = () => {
    

    return (
        <div id="HotelsPage" className="hotels-page main-content">
            <AirlineFilter rejected="JL">
                <Navigate replace to={{ pathname: "/", }} />
            </AirlineFilter>
            <HotelQueuesMonitor />
            <HandleHotelEventsToCrewManagerForm />
            <SyncHotelForm />
        </div>
    );
}

export default HotelsPage;

