import * as React from 'react';
// import { Button } from '@progress/kendo-react-buttons';
import { setQueueStatus } from '../../providers/MicrohubApiProvider';
import { AppContext } from '../../AppContext';
import { loadQueuesState, updateQueueState } from '../../providers/QueueStateProvider';
import { useDispatch, useSelector } from 'react-redux';
import { Form, FormElement } from '@progress/kendo-react-form';
import LoadingButton from '../LoadingButton';
import AlertSuccess from '../AlertSuccess';
import AlertFailure from '../AlertFailure';
import {Refresh as RefreshIcon} from '@mui/icons-material';


export const QueueStatusManager = ({title, legend, code}) => {
    const { getIdToken } = React.useContext(AppContext);
    const dispatch = useDispatch();
    const [errorMsg, setErrorMsg] = React.useState(false);
    const [successMsg, setSuccessMsg] = React.useState(false);

    const queues = useSelector( state => state.queues && state.queues) ;
    const queueInfo = queues.filter(queue => queue.queueName === code).pop()
    const bufferEnabled = queueInfo.status === "Active";
    const refreshQueues = () => {
        loadQueuesState(getIdToken, dispatch);
    }
    const onSubmit = async () => {
        try{
            setSuccessMsg(false);
            var idToken = await getIdToken();
            var rawIdToken = idToken.idToken.rawIdToken;
            var newQueueState = await setQueueStatus(code, true, !bufferEnabled, rawIdToken);
            setSuccessMsg(bufferEnabled ? "Buffering events" : "Processing events");
            updateQueueState(dispatch, newQueueState);
        }catch(e){
            setErrorMsg("Unexpected error. Please try again later")
        }
    }
    const stateTitle = bufferEnabled ? "Queue is ACTIVE." : "Queue is Buffering."
    const stateDescription = bufferEnabled ? "Events are flowing." : "Events are NOT flowing."
    const buttonLabel = bufferEnabled ? "Stop processing events" : "Start processing events"
    return (
        <div id="SubscriptionsPage" className="subscriptions-page main-content">
            <div className="card-container grid">
                <h3 className="card-title">{title}</h3>
                <div className="card-ranges">
                    <LoadingButton onClick={refreshQueues}><RefreshIcon/></LoadingButton>
                </div>
                <div className="card-component">
                    <Form 
                        // onSubmit={onSubmit}
                        render={(formRenderProps) => (
                            <FormElement>
                                <fieldset  className={'k-form-fieldset'}>
                                    <legend className={'k-form-legend'}>{legend}</legend>
                                            <h4>{stateTitle}</h4>
                                            <div>{stateDescription}</div>
                                            <div className="k-form-buttons">
                                                <LoadingButton onClick={onSubmit}>{buttonLabel}</LoadingButton>
                                            </div>
                                </fieldset>
                            </FormElement>

                        )}

                    />
                </div>
                <AlertSuccess
                    message={successMsg}
                    onClose={()=>setSuccessMsg(false)}
                 />
                <AlertFailure
                    message={errorMsg}
                    onClose={() => setErrorMsg(false)}
                />
            </div>
        </div>
    );

}