
import * as React from 'react';
import { Navigate } from 'react-router-dom';
import { AirlineFilter } from '../components/AirlineFilter';
import { HandleEventsToCrewManagerForm } from '../components/Reservations/HandleEventsToCrewManagerForm';


import { ReservationQueuesMonitor } from '../components/Reservations/ReservationQueuesMonitor';
import { SyncReservationForm } from '../components/Reservations/SyncReservationForm';

const ReservationsPage = () => {
    

    return (
        <div id="ReservationsPage" className="reservations-page main-content">
            
            <AirlineFilter rejected="JL">
                <Navigate replace to={{ pathname: "/", }} />
            </AirlineFilter>
            <ReservationQueuesMonitor />
            <SyncReservationForm />
            <HandleEventsToCrewManagerForm />
        </div>
    );
}

export default ReservationsPage;

