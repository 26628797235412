
import * as React from 'react';
import { Navigate } from 'react-router-dom';
import { AirlineFilter } from '../components/AirlineFilter';
import { HandleTransportEventsToCrewManagerForm } from '../components/Transport/HandleTransportEventsToCrewManagerForm';

import { SyncHotelShuttleForm } from '../components/Transport/SyncHotelShuttleForm';
import { SyncTransportForm } from '../components/Transport/SyncTransportForm';
import { TransportQueuesMonitor } from '../components/Transport/TransportQueuesMonitor';

const TransportsPage = () => {
    

    return (
        <div id="TransportsPage" className="transports-page main-content">
            <AirlineFilter rejected="JL">
                <Navigate replace to={{ pathname: "/", }} />
            </AirlineFilter>
            <TransportQueuesMonitor />
            <SyncTransportForm />
            <SyncHotelShuttleForm />
            <HandleTransportEventsToCrewManagerForm />
        </div>
    );
}

export default TransportsPage;

