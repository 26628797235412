import * as React from 'react';
import { Form, Field, FormElement } from '@progress/kendo-react-form';
import { FormFloatingFormInput  } from '../form-components';
import SubmitableLoadingButton from '../SubmitableLoadingButton';
import { AppContext } from '../../AppContext';
import { runHotelSync } from '../../providers/MicrohubApiProvider';
import AlertFailure from '../AlertFailure';
import AlertSuccess from '../AlertSuccess';



const hotelIdRegex = /^[0-9]{1,4}$/
const hotelIdValidator = (value) =>  ( typeof(value) === "string" &&  value.match(hotelIdRegex) ) ? "":"Hotel ID must be numeric"  ;


export const SyncHotelForm = (props) => {
    const [errorMsg, setErrorMsg] = React.useState(false);
    const [successMsg, setSuccessMsg] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const { getIdToken, airlineCode } = React.useContext(AppContext);

    const onSubmit = async (data) => {
        console.log("Submitting", data);
        setLoading(true)
        try{
            var idToken = await getIdToken();
            var rawIdToken = idToken.idToken.rawIdToken;
            await runHotelSync(airlineCode, [data.hotelId],rawIdToken);
            setSuccessMsg("Hotel sync completed")
        }catch(e){
            setErrorMsg("Unexpected error. Please try again later")
        }
        setLoading(false)
    };

    return <>
        <div className="card-container grid">
                <h3 className="card-title">Sync Hotel</h3>
                <div className="card-component">
                    <Form 
                        onSubmit={onSubmit}
                        render={(formRenderProps) => (
                            <FormElement>
                                <fieldset  className={'k-form-fieldset'}>
                                    <legend className={'k-form-legend'}>Force a specific hotel to be sent to Crew Manager</legend>

                                    <Field
                                        id={'hotelIdentification'}
                                        name={'hotelId'}
                                        label={'Hotel Identification'}

                                        component={FormFloatingFormInput}
                                        validator={hotelIdValidator}
                                    />

                                    <div className="k-form-buttons">
                                        <SubmitableLoadingButton 
                                            loading={loading} 
                                            disabled={!formRenderProps.allowSubmit}>
                                            Sync Hotel
                                        </SubmitableLoadingButton>
                                     </div>
                                </fieldset>
                            </FormElement>

                        )}

                    />
                </div>
                <AlertSuccess
                    message={successMsg}
                    onClose={()=>setSuccessMsg(false)}
                 />
                <AlertFailure 
                    message={errorMsg}
                    onClose={() => setErrorMsg(false)}
                />
            </div>
    </>;



}