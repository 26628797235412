
import * as React from 'react';

import { HandleCrewsBufferForm } from '../components/Crews/HandleCrewsBufferForm';
import { HandlePairingsBufferForm } from '../components/Pairings/HandlePairingsBufferForm';
import { HandleEventsToCrewManagerForm } from '../components/Reservations/HandleEventsToCrewManagerForm';
import { HandleHotelEventsToCrewManagerForm } from '../components/Hotels/HandleHotelEventsToCrewManagerForm';
import { HandleTransportEventsToCrewManagerForm } from '../components/Transport/HandleTransportEventsToCrewManagerForm';
import { AirlineFilter } from '../components/AirlineFilter';
import { Navigate } from 'react-router-dom';


const OutagesPage = () => {
    return (
        <div id="CrewsPage" className="crews-page main-content">
            <AirlineFilter rejected="JL">
                <Navigate replace to={{ pathname: "/", }} />
            </AirlineFilter>
            <HandlePairingsBufferForm />
            <HandleCrewsBufferForm />
            <HandleEventsToCrewManagerForm />
            <HandleHotelEventsToCrewManagerForm />
            <HandleTransportEventsToCrewManagerForm />
        </div>
    );
}

export default OutagesPage;

