import { deleteAirlineBidPeriod, getAirlineBidPeriods, updateAirlineBidPeriod } from "./MicrohubApiProvider"
import { setCurrentAirlineBidPeriods, updateCurrentAirlineBidPeriod , deleteCurrentAirlineBidPeriod, addCurrentAirlineBidPeriod} from "./redux/Actions";

export const loadBidPeriods = async (getIdToken, airlineCode, dispatch) => {
    var idToken = await getIdToken();
    var rawIdToken = idToken.idToken.rawIdToken;
    var bidPeriods = await getAirlineBidPeriods(airlineCode, rawIdToken );
    dispatch(setCurrentAirlineBidPeriods(bidPeriods));
} 

export const updateBidPeriod = async (getIdToken, airlineCode, bidPeriod, dispatch) => {
    var idToken = await getIdToken();
    var rawIdToken = idToken.idToken.rawIdToken;
    var updatedBidPeriod = await updateAirlineBidPeriod(airlineCode, bidPeriod, rawIdToken );
    dispatch(updateCurrentAirlineBidPeriod(updatedBidPeriod));
} 

export const createBidPeriod = async (getIdToken, airlineCode, bidPeriod, dispatch) => {
    var idToken = await getIdToken();
    var rawIdToken = idToken.idToken.rawIdToken;
    var newBidPeriod = await updateAirlineBidPeriod(airlineCode, bidPeriod, rawIdToken );
    dispatch(addCurrentAirlineBidPeriod(newBidPeriod));
} 

export const deleteBidPeriod = async (getIdToken, airlineCode, bidPeriodId, dispatch) => {
    var idToken = await getIdToken();
    var rawIdToken = idToken.idToken.rawIdToken;
    var oldBidPeriod = await deleteAirlineBidPeriod(airlineCode, bidPeriodId, rawIdToken );
    dispatch(deleteCurrentAirlineBidPeriod(oldBidPeriod));
} 
