import {
    combineReducers
} from 'redux'
import {actions} from './Actions';


// const initialState = {
//     loading: false,
//     aadResponse: null,
// };

const initialAad = { response: null}
const aad = (state = initialAad, action) => {
    switch (action.type) {
        case actions.AAD_LOGIN_SUCCESS:
            return {
                ...state, response: action.payload
            };
        case actions.AAD_LOGOUT_SUCCESS:
            return {
                ...state, response: null
            };
        default:
            return state;
    }
};

const loading = (state = false, action) => {
    switch (action.type) {
        case actions.START_LOADING:
            return true;
        case actions.STOP_LOADING:
            return false;
        default:
            return state;
    }

}
const queues = (state = [], action) => {
    switch(action.type){
        case actions.SET_QUEUES_STATE:
            return [...action.queues];
        case actions.UPDATE_QUEUE_STATE:
            return [...state.filter(queue => queue.queueName !== action.queue.queueName), action.queue];
        default:
            return state;
    }
}

const initialCurrentAirline = {airlineCode :"HA", bidPeriods:[]}

const currentAirline = (state = initialCurrentAirline, action) => {
    switch (action.type) {
        case actions.SET_CURRENT_AIRLINE:
            return { airlineCode:action.airlineCode, bidPeriods:[] };
        case actions.SET_CURRENT_AIRLINE_BID_PERIODS:
            return {...state, bidPeriods:action.bidPeriods}
        case actions.UPDATE_CURRENT_AIRLINE_BID_PERIOD:
            return {...state, bidPeriods: state.bidPeriods.map(bp => bp.id === action.bidPeriod.id ? action.bidPeriod : bp )  }
        case actions.ADD_CURRENT_AIRLINE_BID_PERIOD:
            return {...state, bidPeriods: [...state.bidPeriods, action.bidPeriod]}
        case actions.REMOVE_CURRENT_AIRLINE_BID_PERIOD:
            return {...state, bidPeriods: state.bidPeriods.filter(bp => bp.id !== action.bidPeriod.id)}
        case actions.SET_CURRENT_AIRLINE_LOCKS:
            return {...state, locks:action.locks};
        case actions.REMOVE_CURRENT_AIRLINE_LOCKS:
            var newState = {...state}
            delete newState.locks;
            return newState;
        default:
            return state;
    }
}

const initialFeatureSettings = {
    "crewManagerSendRoomsWithCancelledStatus": false,
    "crewManagerHotelNotRequiredFixOnRepeatedFlightNumbers": false,
    "crewManagerSetInternationalFlagFromCustomAttribute": false,
    "crewManagerParseRosterDeadhedCustomAttributes": false,
    "enableHawaiianAirlines": false,
    "enableJapanAirlines": true,
}

const featureSettings = (state = initialFeatureSettings, action) => {
    switch(action.type){
        case actions.SET_FEATURE_SETTINGS:
            return {...action.featureSettings}
        default:
            return {...state}
    }
}

const MicrohubUiReducers = combineReducers({
    aad, 
    loading, 
    queues, 
    currentAirline,
    featureSettings
})

export default MicrohubUiReducers;