import * as React from 'react';
import { Form, Field, FormElement } from '@progress/kendo-react-form';
import { FormFloatingDropDownList } from '../form-components';
import AlertFailure from '../AlertFailure';
import AlertSuccess from '../AlertSuccess';
import SubmitableLoadingButton from '../SubmitableLoadingButton';
import { AppContext } from '../../AppContext';
import { runPairingBidPeriodLoad } from '../../providers/MicrohubApiProvider';
import { useSelector } from 'react-redux';



const requiredValidator = (value) => value ? "" : "Error: This field is required.";

const formatBidPeriod = value => `${value.substring(0,4)}-${value.substring(4,6)}`
const formatBidPeriodDate = value => `${value.substring(0,4)}-${value.substring(4,6)}-${value.substring(6,8)}`

const sortBidPeriods = (a,b) => {
    if(a.bidPeriod.trim() < b.bidPeriod.trim()) return 1;
    if(a.bidPeriod.trim() > b.bidPeriod.trim()) return -1;
    if(a.bidPeriod.trim() === b.bidPeriod.trim()) return 0;
}
export const MonthlyLoadOnBidPeriodForm = (props) => {

    const [errorMsg, setErrorMsg] = React.useState(false);
    const [successMsg, setSuccessMsg] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const { getIdToken, airlineCode } = React.useContext(AppContext);


    var currentAirline = useSelector(state => state.currentAirline);
    var showBidMonth = (bp) => {
        var month = bp.bidPeriod.trim();
        return month.substr(4,2) + "/" + month.substr(0,4);
    }
    var activeBidMonths = currentAirline.bidPeriods.filter(bp => bp.activeBidInd).map(showBidMonth);
    const onSubmit = async (data) => {
        console.log("Submitting", data);
        setErrorMsg(false);
        setSuccessMsg(false)
        setLoading(true)
        try{
            var idToken = await getIdToken();
            var rawIdToken = idToken.idToken.rawIdToken;
            await runPairingBidPeriodLoad(airlineCode, data.bidPeriod.id, "ROSTER_LOAD", rawIdToken);
            setSuccessMsg("Crew sync completed")
        }catch(e){
            if(e.response && e.response.data){
                setErrorMsg(e.response.data);
            }else{
                setErrorMsg("Unexpected error. Please try again later");
            }
        }
        setLoading(false)
    };
    var availableBidPeriods = currentAirline.bidPeriods.sort(sortBidPeriods).filter(bp => bp.activeBidInd).map(bp => ({id:bp.id, text:`${formatBidPeriod(bp.bidPeriod)}  (${formatBidPeriodDate(bp.bidStart)} - ${formatBidPeriodDate(bp.bidEnd)})` }));
    console.log("Active bid period", availableBidPeriods);
    // var bidPeriodsAvailable = activeBid
    console.log("ENVIRONEMTN:",process.env.REACT_APP_ENV_NAME)
    return <>
        <div className="card-container grid">
                <h3 className="card-title">Monthly Load</h3>
                <div className="card-component">
                    <Form 
                        onSubmit={onSubmit}
                        render={(formRenderProps) => (
                            <FormElement>
                                <fieldset  className={'k-form-fieldset'}>
                                    
                                    <legend className={'k-form-legend'}>Load pairings for a specific bid period.</legend>
                                    
                                    {activeBidMonths.length === 0 && <p className="text-warning">There are no active bid months</p>}
                                    {activeBidMonths.length > 0 && <div>
                                        <Field
                                            id={'process-bid-period'}
                                            name={'bidPeriod'}
                                            label={'Bid Period'}
                                            hint={'Hint: Choose a bid period'}
                                            component={FormFloatingDropDownList}
                                            data={availableBidPeriods}
                                            validator={requiredValidator}
                                            textField="text"
                                            dataItemKey="id"
                                        />
                                        <div className="k-form-buttons">
                                            <SubmitableLoadingButton
                                                loading={loading} 
                                                disabled={!formRenderProps.allowSubmit}>
                                                Load Pairings
                                            </SubmitableLoadingButton>
                                        </div>
                                    </div>}


                                </fieldset>
                            </FormElement>
                        )}
                    />
                </div>
                <AlertSuccess
                    message={successMsg}
                    onClose={()=>setSuccessMsg(false)}
                 />
                <AlertFailure 
                    message={errorMsg}
                    timeoutMs={10000}
                    onClose={() => setErrorMsg(false)}
                />
            </div>
    </>;



}