import React from 'react';
import {Routes, Route, Navigate } from 'react-router-dom';

import PairingsPage from '../pages/PairingsPage';
import CrewsPage from '../pages/CrewsPage';
import ReservationsPage from '../pages/ReservationsPage';
import HotelsPage from '../pages/HotelsPage';
import TransportsPage from '../pages/TransportsPage';
import SubscriptionsPage from '../pages/SubscriptionsPage';
import DashboardPage from '../pages/DashboardPage.js';
import BidPeriodsPage from '../pages/BidPeriodsPage';
import OutagesPage from '../pages/OutagesPage';
import WalkingTimesPage from '../pages/WalkingTimesPage';
import GeneralSettingsPage from '../pages/GeneralSettingsPage';


function AppNavigationSwitch(){
    var routeToHome = <Navigate replace to={{ pathname: "/", }} />;
    return <>
    <Routes>
        <Route exact={true} path="/" element={<DashboardPage/>} />
        <Route exact={true} path="/pairings" element={<PairingsPage/>} />
        <Route exact={true} path="/bid-periods" element={<BidPeriodsPage/>} />
        <Route exact={true} path="/crews" element={<CrewsPage/>} />
        <Route exact={true} path="/general-settings" element={<GeneralSettingsPage/>} />

        <Route path="/reservations" element={<ReservationsPage/>} defaultElement={routeToHome} />
        <Route path="/hotels" element={<HotelsPage/>} defaultElement={routeToHome} />
        <Route path="/transports" element={<TransportsPage/>} defaultElement={routeToHome} />
        <Route path="/subscriptions" element={<SubscriptionsPage/>} defaultElement={routeToHome} />
        <Route path="/outages" element={<OutagesPage/>} defaultElement={routeToHome} />
        <Route path="/walking-times" element={<WalkingTimesPage/>} defaultElement={routeToHome} />

        <Route path="*" element={<Navigate replace to={{ pathname: "/", }} />} />

    </Routes>
        
    </>
}


export default AppNavigationSwitch;