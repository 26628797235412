import * as React from 'react';
import { Form, Field, FormElement } from '@progress/kendo-react-form';
import { FormFloatingFormInput, } from '../form-components';
import { AppContext } from '../../AppContext';
import { runProviderSync } from '../../providers/MicrohubApiProvider';
import SubmitableLoadingButton from '../SubmitableLoadingButton';
import AlertFailure from '../AlertFailure';
import AlertSuccess from '../AlertSuccess';




const providerIdRegex = /[0-9]{1,4}/
const providerIdValidator = (value) =>  ( typeof(value) !== "string" ||  value.match(providerIdRegex)) ? "" : "Invalid Crew ID"

export const SyncTransportForm = (props) => {
    const [errorMsg, setErrorMsg] = React.useState(false);
    const [successMsg, setSuccessMsg] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const { getIdToken, airlineCode } = React.useContext(AppContext);
    const onSubmit = async (data) => {
        setLoading(true);
        try{
            var idToken = await getIdToken();
            var rawIdToken = idToken.idToken.rawIdToken;
            await runProviderSync(airlineCode, [data.providerId],rawIdToken);
            setSuccessMsg("Transport provider sync completed")
        }catch(e){
            setErrorMsg("Unexpected error. Please try again later")
        }
        setLoading(false);
    };
    

    return <>
        <div className="card-container grid">
                <h3 className="card-title">Sync Provider</h3>
                <div className="card-component">
                    <Form 
                        onSubmit={onSubmit}
                        render={(formRenderProps) => (
                            <FormElement>
                                <fieldset  className={'k-form-fieldset'}>
                                    <legend className={'k-form-legend'}>Force a specific transport provider to be sent to Crew Manager</legend>

                                    <Field
                                        id={'transportIdentification'}
                                        name={'providerId'}
                                        label={'External Provider Id'}
                                        component={FormFloatingFormInput}
                                        validator={providerIdValidator}
                                    />

                                    <div className="k-form-buttons">
                                        <SubmitableLoadingButton 
                                            loading={loading} 
                                            disabled={!formRenderProps.allowSubmit}>
                                        Sync Transport Provider
                                        </SubmitableLoadingButton>
                                        
                                     </div>
                                </fieldset>
                            </FormElement>

                        )}

                    />
                </div>
                <AlertSuccess
                    message={successMsg}
                    onClose={()=>setSuccessMsg(false)}
                 />
                <AlertFailure
                    message={errorMsg}
                    onClose={() => setErrorMsg(false)}
                />
            </div>
    </>;



}