import React from 'react';
import { AppContext } from '../AppContext';
export default function Loading({ children }){
    const { showLoading } = React.useContext(AppContext);
    return (
        <>
          {showLoading && <div id="loading-layer">Loading&#8230;</div>}      
          { children }
        </>
    );
}