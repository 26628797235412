import * as React from 'react';
import { AppContext } from '../AppContext';

export const AirlineFilter = (props) => {
    var {airlineCode } = React.useContext(AppContext);

    if(props && props.allowed) 
    {
        if(Array.isArray(props.allowed) && props.allowed.indexOf(airlineCode) !== -1)
        {
            return props.children;
        }
        if(typeof(props.allowed) === "string" && props.allowed === airlineCode)
        {
            return props.children;
        }
    }
    if(props && props.rejected)
    {
        if(Array.isArray(props.rejected) && props.rejected.indexOf(airlineCode) !== -1)
        {
            return <></>;
        }
        if(typeof(props.rejected) === "string" && props.rejected === airlineCode)
        {
            return <></>;
        }
        return props.children;
    }
    return <></>;

}