
import * as React from 'react';

import { BidPeriodForm } from '../components/Pairings/BidPeriodForm';



const BidPeriodsPage = () => {

    return (
        <div id="BidPeriodsPage" className="pairings-page main-content">
            <BidPeriodForm />
        </div>
    );
}

export default BidPeriodsPage;

