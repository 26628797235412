import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useMsalAuthentication } from "@azure/msal-react";

const theme = createTheme();

export function LoginPage()
{
    const {login} = useMsalAuthentication("redirect");
    const handleSubmit = (event) => {
        // instance.loginPopup()
        login();
      };

    return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <img 
            src={"/images/TAC_Logo.png"}
            alt={"TAConnections"}
            />
          
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <Typography component="p" variant="p">
                We are redirecting you to the login page...
            </Typography>
            <Typography component="p" variant="p">
                If nothing shouls up, click 
                <Button
                    type="submit"
                    variant="text"
                    >
                    here
                </Button>.
            </Typography>
            
          </Box>
        </Box>
        {/* <Copyright sx={{ mt: 8, mb: 4 }} /> */}
      </Container>
    </ThemeProvider>
    );
}