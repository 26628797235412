import * as React from 'react';
import { Form, Field, FormElement } from '@progress/kendo-react-form';
import { FormAutoComplete, FormFloatingDropDownList } from '../form-components';
import { AppContext } from '../../AppContext';
import { setSubscriptionWebhook } from '../../providers/MicrohubApiProvider';
import {CONSTANTS } from '../../constants';
import SubmitableLoadingButton from '../SubmitableLoadingButton';
import AlertFailure from '../AlertFailure';
import AlertSuccess from '../AlertSuccess';


const crewManagerSubscriptionEventTypes = CONSTANTS.CREWMANAGER.SUBSCRIPTIONS.EVENT_TYPES;
const urlRegex = /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/;
const urlValidator = (value) => (typeof(value) === "string" && value.length > 0 &&  value.match(urlRegex) ) ? "" : "The URL is invalid" ;

export const SubscribeToEventForm = (props) => {
    const [errorMsg, setErrorMsg] = React.useState(false);
    const [successMsg, setSuccessMsg] = React.useState(false);
    const { getIdToken } = React.useContext(AppContext);
    const [loading, setLoading] = React.useState(false);

    const onSubmit = async (data) => {
        setLoading(true);
        try{
            const eventName = data.eventType.id
            var idToken = await getIdToken();
            var rawIdToken = idToken.idToken.rawIdToken;
            const response = await setSubscriptionWebhook(eventName, data.webhook, rawIdToken);
            const subscriptionStatus= response.subscriptionStatus;
            console.log(subscriptionStatus);
            setSuccessMsg(`Subscription to ${eventName} succeeded`);
        }catch(e){
            setErrorMsg("Unexpected error. Please try again later")
        }

        setLoading(false);
    };

    const suggestedEndpoints = CONSTANTS.FRONT_DESK_CORE.SUGGESTED_ENDPOINTS;
    const requiredValidator = (value) => (value && value.id) ? "" : "Error: This field is required.";
    return <>
        <div className="card-container grid">
                <h3 className="card-title">Subscribe to event</h3>
                <div className="card-component">
                    <Form 
                        onSubmit={onSubmit}
                        render={(formRenderProps) => (
                            <FormElement>
                                <fieldset  className={'k-form-fieldset'}>
                                    <legend className={'k-form-legend'}>Subscribe a Crew Manager Event to a Webhook</legend>

                                    
                                    <Field
                                        id={'process-event-type'}
                                        name={'eventType'}
                                        label={'Crew Manager Event'}
                                        hint={'Hint: Choose a Crew Manager Event'}
                                        component={FormFloatingDropDownList}
                                        data={crewManagerSubscriptionEventTypes}
                                        validator={requiredValidator}
                                        textField="text"
                                        dataItemKey="id"
                                    />

                                    <Field
                                        id={'webhookIdentification'}
                                        name={'webhook'}
                                        label={'Webhook URL'}
                                        component={FormAutoComplete}
                                        validator={urlValidator}
                                        suggest={true}
                                        data={suggestedEndpoints}
                                    />

                                     <div className="k-form-buttons">
                                        <SubmitableLoadingButton 
                                            loading={loading} 
                                            disabled={!formRenderProps.allowSubmit}>
                                            Subscribe
                                        </SubmitableLoadingButton>
                                     </div>
                                </fieldset>
                            </FormElement>

                        )}

                    />
                </div>
                <AlertSuccess
                    message={successMsg}
                    onClose={()=>setSuccessMsg(false)}
                 />
                <AlertFailure
                    message={errorMsg}
                    onClose={() => setErrorMsg(false)}
                />
            </div>
    </>;



}