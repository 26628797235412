import * as React from 'react';
import { Form, Field, FormElement } from '@progress/kendo-react-form';
import { FormUpload } from '../form-components';
import AlertFailure from '../AlertFailure';
import AlertSuccess from '../AlertSuccess';
import SubmitableLoadingButton from '../SubmitableLoadingButton';
import { AppContext } from '../../AppContext';
import { runCrewFileLoad } from '../../providers/MicrohubApiProvider';


export const LoadEmployeeDetailsFromFileForm = (props) => {
    const {expectedFileFormat}=props;
    const [errorMsg, setErrorMsg] = React.useState(false);
    const [successMsg, setSuccessMsg] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const { getIdToken, airlineCode } = React.useContext(AppContext);

    const onSubmit = async (data) => {
        console.log("Submitting", JSON.stringify(data, null, 4));
        if(!Array.isArray(data.employeeDetailsFile) || data.employeeDetailsFile.length === 0)
        {
            setErrorMsg(`Please choose a ${expectedFileFormat} file to process`);
            return;
        }
        setErrorMsg(false);
        setSuccessMsg(false)
        setLoading(true)
        try{
            var idToken = await getIdToken();
            var rawIdToken = idToken.idToken.rawIdToken;
            var filedata = data.employeeDetailsFile.map((file) => file.getRawFile());
            var fasContent = await readFileAsync(filedata[0])
            await runCrewFileLoad(airlineCode, "INITIAL_LOAD", filedata[0].name, fasContent, rawIdToken );
            setSuccessMsg("Crew sync completed")
        }catch(e){
            if(e.response && e.response.data){
                setErrorMsg(e.response.data);
            }else{
                setErrorMsg("Unexpected error. Please try again later");
            }
        }
        setLoading(false)
    };
    console.log("ENVIRONEMTN:",process.env.REACT_APP_ENV_NAME)
    return <>
        <div className="card-container grid">
                <h3 className="card-title">Employee Details Load</h3>
                <div className="card-component">
                    <Form 
                        
                        onSubmit={onSubmit}
                        render={(formRenderProps) => (
                            <FormElement>
                                <fieldset  className={'k-form-fieldset'}>
                                    
                                    <legend className={'k-form-legend'}>Load employee details from a <i>"*.{expectedFileFormat}"</i> file.</legend>
                                    

                                        <Field 
                                            id={'employee-details-file'}
                                            name={'employeeDetailsFile'}
                                            label={'Employees Details File'}
                                            hint={`Choose a ${expectedFileFormat} file with employee details.`}
                                            component={FormUpload}
                                        
                                        />
                                        <div className="k-form-buttons">
                                            <SubmitableLoadingButton
                                                loading={loading} 
                                                disabled={!formRenderProps.allowSubmit}>
                                                Load Employee Details
                                            </SubmitableLoadingButton>
                                        </div>

                                </fieldset>
                            </FormElement>
                        )}
                    />
                </div>
                <AlertSuccess
                    message={successMsg}
                    onClose={()=>setSuccessMsg(false)}
                 />
                <AlertFailure 
                    message={errorMsg}
                    timeoutMs={10000}
                    onClose={() => setErrorMsg(false)}
                />
            </div>
    </>;



}

function readFileAsync(file){
    return new Promise( (res, err) => {
        let reader = new FileReader();
        reader.addEventListener("loadend", () => {
          res(reader.result) ;
        });
        reader.addEventListener("error", () => {
            err(reader.error);
        })
        reader.readAsText(file);
    });
}