import * as React from "react";

import { useSelector } from "react-redux";
import { CONSTANTS } from "../../constants";

export function AirlineIndicator(props){
    const currentAirline = useSelector( state => state.currentAirline && state.currentAirline) ;
    var airlineCode = currentAirline.airlineCode;
    var airlineName = CONSTANTS.AIRLINES_BY_CODE[airlineCode];
    var imageSrc = `/images/AirlineLogos/${airlineCode}.png`;
    return <>
        <div id="SubscriptionsPage" className="subscriptions-page main-content">
            <div className="card-container grid" style={{backgroundColor:"#eee"}}>
                <div className="card-component">
                    <div style={{display:'flex', flexDirection:'row'}}>
                        <div ><img src={imageSrc} alt={airlineName} title={airlineName} style={{width:100, height:100}}/></div>
                        <div style={{ paddingLeft:50, display:'flex', flexDirection:'column'}}>
                            <h3 style={{fontSize:"36px"}}>Current airline is <b>{airlineName}</b></h3>
                            <p>Any airline specific operation is going to be applied to {airlineName}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>;
}