export const actions = {
    AAD_LOGIN_SUCCESS:'AAD_LOGIN_SUCCESS',
    AAD_LOGOUT_SUCCESS:'AAD_LOGOUT_SUCCESS',
    
    START_LOADING:'START_LOADING',
    STOP_LOADING:'STOP_LOADING',
    
    SET_QUEUES_STATE: 'SET_QUEUES_STATE',
    UPDATE_QUEUE_STATE: 'UPDATE_QUEUE_STATE',
    
    SET_CURRENT_AIRLINE: 'SET_CURRENT_AIRLINE',
    SET_CURRENT_AIRLINE_BID_PERIODS: 'SET_CURRENT_AIRLINE_BID_PERIODS',
    
    UPDATE_CURRENT_AIRLINE_BID_PERIOD: 'UPDATE_CURRENT_AIRLINE_BID_PERIOD',
    ADD_CURRENT_AIRLINE_BID_PERIOD:'ADD_CURRENT_AIRLINE_BID_PERIOD',
    REMOVE_CURRENT_AIRLINE_BID_PERIOD: 'REMOVE_CURRENT_AIRLINE_BID_PERIOD',

    SET_CURRENT_AIRLINE_LOCKS: 'SET_CURRENT_AIRLINE_LOCKS',
    REMOVE_CURRENT_AIRLINE_LOCKS: 'REMOVE_CURRENT_AIRLINE_LOCKS',

    SET_FEATURE_SETTINGS: 'SET_FEATURE_SETTINGS',
}

export const startLoading = () => {
    return {
        type:actions.START_LOADING
    }
}

export const stopLoading = () => {
    return {
        type:actions.STOP_LOADING
    }
}

export const setQueues = (queues) => {
    return {
        type:actions.SET_QUEUES_STATE,
        queues
    }
}

export const updateQueue = (queue) => {
    return {
        type:actions.UPDATE_QUEUE_STATE,
        queue
    }
}

export const setCurrentAirlineBidPeriods = (bidPeriods) => {
    return {
        type: actions.SET_CURRENT_AIRLINE_BID_PERIODS,
        bidPeriods
    }
}

export const updateCurrentAirlineBidPeriod = (bidPeriod) => {
    return {
        type: actions.UPDATE_CURRENT_AIRLINE_BID_PERIOD,
        bidPeriod
    }
}

export const addCurrentAirlineBidPeriod = (bidPeriod) => {
    return {
        type: actions.ADD_CURRENT_AIRLINE_BID_PERIOD,
        bidPeriod
    }
}

export const deleteCurrentAirlineBidPeriod = (bidPeriod) => {
    return {
        type: actions.REMOVE_CURRENT_AIRLINE_BID_PERIOD,
        bidPeriod
    }
}
export const setCurrentAirline = (airlineCode) => {
    return {
        type:actions.SET_CURRENT_AIRLINE,
        airlineCode
    }
}

export const setCurrentAirlineLocks = (locks) => {
    return {
        type: actions.SET_CURRENT_AIRLINE_LOCKS,
        locks
    }
}

export const removeCurrentAirlineLocks = () => {
    return {
        type: actions.REMOVE_CURRENT_AIRLINE_LOCKS
    }
}

export const setFeatureSettings = (featureSettings) => {
    return {
        type: actions.SET_FEATURE_SETTINGS,
        featureSettings
    }
}

