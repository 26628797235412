import * as React from 'react';
import { Form, Field, FormElement } from '@progress/kendo-react-form';
import {  FormFloatingFormInput, FormRadioGroup } from '../form-components';
import { CONSTANTS } from '../../constants';
import { AppContext } from '../../AppContext';
import SubmitableLoadingButton from '../SubmitableLoadingButton';
import AlertFailure from '../AlertFailure';
import AlertSuccess from '../AlertSuccess';
import { runReservationSync } from '../../providers/MicrohubApiProvider';

const requiredValidator = (value) => value ? "" : "Error: This field is required.";
const pairingIdentificationType = CONSTANTS.PAIRINGS.IDENTIFICATION_TYPES;
const pairingIdRegex = /^[0-9a-zA-Z-_]+$/;
const pairingIdValidator = (value) => (typeof(value) === "string" && value.length > 0 && value.match(pairingIdRegex))  ? "" : "The Pairing is invalid";

export const SyncReservationForm = (props) => {

    const [errorMsg, setErrorMsg] = React.useState(false);
    const [successMsg, setSuccessMsg] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const { getIdToken, airlineCode } = React.useContext(AppContext);
    
    const onSubmit = async (data) => {
        console.log("Submitting", data);
        setLoading(true)
        try{
            var idToken = await getIdToken();
            var rawIdToken = idToken.idToken.rawIdToken;
            const isPatternLabel = data.pairingIdentificationType === "pattern_label"

            await runReservationSync(airlineCode, isPatternLabel, false, [data.patternLabel], rawIdToken);
            setSuccessMsg("Crew sync completed")
        }catch(e){
            setErrorMsg("Unexpected error. Please try again later")
        }
        setLoading(false)
    };
    
    

    return <>
        <div className="card-container grid">
                <h3 className="card-title">Sync single reservation</h3>
                <div className="card-component">
                    <Form 
                        onSubmit={onSubmit}
                        render={(formRenderProps) => (
                            <FormElement>
                                <fieldset  className={'k-form-fieldset'}>
                                    <legend className={'k-form-legend'}>Send a reservation to Crew Manager</legend>

                                    <Field
                                        id={'pairingIdentification'}
                                        name={'patternLabel'}
                                        label={'Pairing Identification (Pattern Label)'}
                                        component={FormFloatingFormInput}
                                        validator={pairingIdValidator}
                                    />


                                    <Field
                                        id={'pairingIdentificationType'}
                                        name={'pairingIdentificationType'}
                                        label={'Is a pattern label or a trip number?'}
                                        layout={'horizontal'}
                                        // defaultValue={pairingIdentificationType[0]}
                                        // defaultValue={'trip_number'}
                                        component={FormRadioGroup}
                                        data={pairingIdentificationType}
                                        validator={requiredValidator}
                                    />

                                     <div className="k-form-buttons">
                                        <SubmitableLoadingButton 
                                            loading={loading} 
                                            disabled={!formRenderProps.allowSubmit}>
                                            Send Pairing Reservations
                                        </SubmitableLoadingButton>
                                     </div>
                                </fieldset>
                            </FormElement>

                        )}

                    />
                </div>
                <AlertSuccess
                    message={successMsg}
                    onClose={()=>setSuccessMsg(false)}
                 />
                <AlertFailure
                    message={errorMsg}
                    onClose={() => setErrorMsg(false)}
                />
            </div>
    </>;



}