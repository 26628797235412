import * as React from 'react';

import {CONSTANTS} from '../../constants'
import { NumberIndicatorGroup } from '../CustomComponents/NumberIndicatorGroup';
import { useSelector } from 'react-redux';
import prettyCamelCase from '../../providers/Tools';
import prettyBytes from 'pretty-bytes';


export const QueueInfoGadget = ({name, code}) => {
    // const queueInfo = useSelector( state => Array.isArray(state.queues) && state.queues.filter(queue => queue.queueName === code).length > 0 && state.queues.filter(queue => queue.queueName === code).pop()) ;
    const queues = useSelector( state => state.queues) ;
    // console.log("queues:", queues);
    const queueInfo = queues.filter(queue => queue.queueName === code).pop()
    // console.log("queues:", queues);
    var size = prettyBytes((queueInfo && queueInfo.sizeInBtyes) || 0);
    size = size.split(" ");
    // if(!queueInfo || !queueInfo.queueName || ! queueInfo.status){
    //     console.log("Missing stuff for ", code)
    //     console.log("queues:", queues);
    // }
    // console.log(`Queue status for ${queueInfo.queueName} is ${queueInfo.status}`)

    const queueStatus = (queueInfo && queueInfo.status && CONSTANTS.QUEUE_STATUSES[queueInfo.status]) || "N/A";
    // console.log(name, queueStatus)
    const indicators = [
        {
            title:"Status",
            value:prettyCamelCase((queueStatus) || 'N/A'),
            color:CONSTANTS.HTML_COLORS.lemonTeam,
        },
        {
            title:"Active",
            value:(queueInfo && queueInfo.activeMessageCount) || 0,
            units:"Messages",
            color:CONSTANTS.HTML_COLORS.tealColor,
        },
        {
            title:"Dead-lettered",
            value:(queueInfo && queueInfo.deadLetterMessageCount) || 0,
            units:"Messages",
            color:CONSTANTS.HTML_COLORS.textDanger,
        },
        {
            title:"Queue size",
            value:size[0],
            units:size[1],
            color:CONSTANTS.HTML_COLORS.backgroundColor,
        },
    ]
    return (
        <div>
            <h4>{name}</h4>
            {/* <div>
            <Chart >
                <ChartLegend position="bottom" orientation="horizontal" />
                <ChartSeries>
                    <ChartSeriesItem type="scatterLine" data={seriesData.one} name="New messages"/>
                    <ChartSeriesItem type="scatterLine" data={seriesData.two} name="Processed messages" />
                </ChartSeries>
            </Chart>
            </div> */}
            <NumberIndicatorGroup
                wrap={true}
                data={indicators}
                />
            
        </div>
    );
}