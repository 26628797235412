import * as React from 'react';
import { Form, Field, FormElement } from '@progress/kendo-react-form';
import { FormFloatingDropDownList,   } from '../form-components';
import { AppContext } from '../../AppContext';
import { delSubscription} from '../../providers/MicrohubApiProvider';
import SubmitableLoadingButton from '../SubmitableLoadingButton';
import AlertFailure from '../AlertFailure';
import AlertSuccess from '../AlertSuccess';
import { CONSTANTS } from '../../constants';



const crewManagerSubscriptionEventTypes = CONSTANTS.CREWMANAGER.SUBSCRIPTIONS.EVENT_TYPES;


export const UnSubscribeToEventForm = (props) => {
    const [errorMsg, setErrorMsg] = React.useState(false);
    const [successMsg, setSuccessMsg] = React.useState(false);
    const onSubmit = async (data) => {
        setLoading(true);
        try{
            const eventName = data.eventType.id
            var idToken = await getIdToken();
            var rawIdToken = idToken.idToken.rawIdToken;
            await delSubscription(eventName, rawIdToken);
            setSuccessMsg(`Subscription to ${eventName} succeeded`);
        }catch(e){
            setErrorMsg("Unexpected error. Please try again later")
        }
        setLoading(false);
    };
    const { getIdToken } = React.useContext(AppContext);
    const [loading, setLoading] = React.useState(false);

    const requiredValidator = (value) => (value && value.id) ? "" : "Error: This field is required.";

    return <>
        <div className="card-container grid">
                <h3 className="card-title">Unsubscribe to event</h3>
                <div className="card-component">
                    <Form 
                        onSubmit={onSubmit}
                        render={(formRenderProps) => (
                            <FormElement>
                                <fieldset  className={'k-form-fieldset'}>
                                    <legend className={'k-form-legend'}>Unsubscribe from a Crew Manager Event</legend>

                                    
                                    <Field
                                        id={'process-event-type'}
                                        name={'eventType'}
                                        label={'Crew Manager Event'}
                                        hint={'Hint: Choose a Crew Manager Event'}
                                        component={FormFloatingDropDownList}
                                        data={crewManagerSubscriptionEventTypes}
                                        validator={requiredValidator}
                                        textField="text"
                                        dataItemKey="id"
                                    />

                                     <div className="k-form-buttons">
                                        <SubmitableLoadingButton 
                                            loading={loading} 
                                            disabled={!formRenderProps.allowSubmit}>
                                            Unsubscribe
                                        </SubmitableLoadingButton>
                                     </div>
                                </fieldset>
                            </FormElement>

                        )}

                    />
                </div>
                <AlertSuccess
                    message={successMsg}
                    onClose={()=>setSuccessMsg(false)}
                 />
                <AlertFailure
                    message={errorMsg}
                    onClose={() => setErrorMsg(false)}
                />
            </div>
    </>;



}