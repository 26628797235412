import * as React from 'react';
import { Form, Field, FormElement } from '@progress/kendo-react-form';
import { FormSwitch  } from '../form-components';
import SubmitableLoadingButton from '../SubmitableLoadingButton';
import { AppContext } from '../../AppContext';
import AlertFailure from '../AlertFailure';
import AlertSuccess from '../AlertSuccess';
import {loadFeatureSettingsAsync} from "../../providers/FeatureSettingsProvider";
import { useDispatch, useSelector } from 'react-redux';
// import { Button } from '@progress/kendo-react-buttons';
import {Button} from '@mui/material';

import { setFeatureSettingsAsync } from '../../providers/MicrohubApiProvider';
import {Refresh as RefreshIcon} from '@mui/icons-material';



const areSettingsEqual = (newValue, oldValue, otro) => {
    var differences = Object.keys(newValue).filter(prop => newValue[prop] !== oldValue[prop]);
    var isEqual =  differences.length === 0;
    return isEqual;
}
const delayMs = ms => new Promise(res => setTimeout(res, ms));
export const FeatureSettingsForm = (props) => {
    const [errorMsg, setErrorMsg] = React.useState(false);
    const [successMsg, setSuccessMsg] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [hasChanges, setHasChanges] = React.useState(false);
    const { getIdToken } = React.useContext(AppContext);
    const dispatch = useDispatch();
    const featureSettings = useSelector(state => state.featureSettings, areSettingsEqual);
    const [formFeatureSettings, setFormFeatureSettings] = React.useState(featureSettings);
    React.useEffect( () => {
        console.log("Feature settings updated");
        setFormFeatureSettings(featureSettings);
    }, [featureSettings])
    const onSubmit = async (data) => {
        if(!window.confirm("Are you sure you want to change feature settings?")){
            return;
        }
        setLoading(true)
        try{
            var idToken = await getIdToken();
            var rawIdToken = idToken.idToken.rawIdToken;
            await setFeatureSettingsAsync(formFeatureSettings, rawIdToken);
            await delayMs(500);
            await loadFeatureSettingsAsync(getIdToken, dispatch);
            setSuccessMsg("Hotel sync completed")
            setHasChanges(false);
        }catch(e){
            setErrorMsg("Unexpected error. Please try again later")
        }
        setLoading(false)
    };

      const refreshSettings = async () => {
        await loadFeatureSettingsAsync(getIdToken, dispatch);
      }

      const getFeatureSwitch = (prop) =>{
        var propName = prop.replace( /([A-Z])/g, " $1" );
        propName = propName[0].toUpperCase() + propName.substring(1);
        const featureChanged = (event) => {
            setHasChanges(true)
            var newSFormFeatureSettings = {...formFeatureSettings};
            newSFormFeatureSettings[prop]=event.target.value;
            setFormFeatureSettings(newSFormFeatureSettings);
        }
        return <Field
            id={prop}
            key={prop}
            name={prop}
            label={propName}
            value={ formFeatureSettings[prop] === true }
            checked={ formFeatureSettings[prop] === true }
            component={FormSwitch}
            onChange={featureChanged}
        />

      };

    return <>
        <div className="card-container grid">
                <h3 className="card-title">Feature Settings</h3>
                <div className="card-ranges">
                    <Button color="primary" variant="contained" onClick={() => refreshSettings()}><RefreshIcon /> </Button>
                </div>
                <div className="card-component">
                    <Form
                        onSubmit={onSubmit}
                        render={() => (
                            <FormElement>
                                <fieldset  className={'k-form-fieldset'}>
                                    <legend className={'k-form-legend'}>Enable / Disable specific features on MicroHUB</legend>

                                    { Object.keys(featureSettings).map(getFeatureSwitch) }

                                    <div className="k-form-buttons">
                                        <SubmitableLoadingButton
                                            loading={loading}
                                            disabled={!hasChanges}>
                                            Update Feature Settings
                                        </SubmitableLoadingButton>
                                     </div>
                                </fieldset>
                            </FormElement>
                        )}
                    />
                </div>
                <AlertSuccess
                    message={successMsg}
                    onClose={()=>setSuccessMsg(false)}
                 />
                <AlertFailure
                    message={errorMsg}
                    onClose={() => setErrorMsg(false)}
                />
            </div>
    </>;



}