import * as React from 'react';
import { Form, Field, FormElement } from '@progress/kendo-react-form';
import { FormFloatingDropDownList, FormFloatingFormInput, } from '../form-components';
import { AppContext } from '../../AppContext';
import SubmitableLoadingButton from '../SubmitableLoadingButton';
import { runCrewSync } from '../../providers/MicrohubApiProvider';
import AlertFailure from '../AlertFailure';
import AlertSuccess from '../AlertSuccess';
import { CONSTANTS } from '../../constants';


const requiredValidator = (value) => value ? "" : "Error: This field is required.";

const crewEventTypes = CONSTANTS.CREWS.EVENT_TYPES;

const crewIdRegex = /[0-9]{1,8}/
const crewIdValidator = (value) =>  ( typeof(value) !== "string" ||  value.match(crewIdRegex)) ? "" : "Invalid Crew ID"

export const SyncCrewForm = (props) => {
    const [errorMsg, setErrorMsg] = React.useState(false);
    const [successMsg, setSuccessMsg] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const { getIdToken, airlineCode} = React.useContext(AppContext);

    const onSubmit = async (data) => {
        console.log("Submitting", data);
        setLoading(true)
        try{
            var idToken = await getIdToken();
            var rawIdToken = idToken.idToken.rawIdToken;
            await runCrewSync(airlineCode, data.eventType.id, [data.crewId],rawIdToken);
            setSuccessMsg("Crew sync completed")
        }catch(e){
            setErrorMsg("Unexpected error. Please try again later")
        }
        setLoading(false)
    };
    
    

    return <>
        <div className="card-container grid">
                <h3 className="card-title">Update single Crew</h3>
                <div className="card-component">
                    <Form 
                        onSubmit={onSubmit}
                        render={(formRenderProps) => (
                            <FormElement>
                                <fieldset  className={'k-form-fieldset'}>
                                    <legend className={'k-form-legend'}>Force a specific crew to be synced</legend>

                                    <Field
                                        id={'crewIdentification'}
                                        name={'crewId'}
                                        label={'Crew Identification'}
                                        component={FormFloatingFormInput}
                                        validator={crewIdValidator}
                                    />


                                    <Field
                                        id={'process-event-type'}
                                        name={'eventType'}
                                        label={'Event Type'}
                                        hint={'Hint: Choose an event type'}
                                        component={FormFloatingDropDownList}
                                        data={crewEventTypes}
                                        validator={requiredValidator}
                                        textField="text"
                                        dataItemKey="id"
                                    />

                                     <div className="k-form-buttons">
                                        <SubmitableLoadingButton 
                                            loading={loading} 
                                            disabled={!formRenderProps.allowSubmit}>
                                            Sync Crew Member
                                        </SubmitableLoadingButton>
                                     </div>
                                </fieldset>
                            </FormElement>

                        )}

                    />
                </div>
                <AlertSuccess
                    message={successMsg}
                    onClose={()=>setSuccessMsg(false)}
                 />
                <AlertFailure 
                    message={errorMsg}
                    onClose={() => setErrorMsg(false)}
                />
            </div>
    </>;



}