import * as React from 'react';
import { Form, Field, FormElement } from '@progress/kendo-react-form';
import { FormFloatingFormInput } from '../form-components';
import { AppContext } from '../../AppContext';
import AlertSuccess from '../AlertSuccess';
import AlertFailure from '../AlertFailure';
import SubmitableLoadingButton from '../SubmitableLoadingButton';
import { runPairingSync } from '../../providers/MicrohubApiProvider';



// const requiredValidator = (value) => value ? "" : "Error: This field is required.";


// const pairingEventTypes = CONSTANTS.PAIRINGS.EVENT_TYPES;

const pairingIdRegex = /^[0-9a-zA-Z-_]+$/;
const getPatternLabelsList = value => value.split(/[^0-9a-zA-Z-_]/).filter(p => p.trim().length > 0);
const isOneOrManyPairingIds = value => {
    var pairingIds = getPatternLabelsList(value);
    console.log(pairingIds)
    return pairingIds.length > 0 && pairingIds.length === pairingIds.filter(pairingId => pairingId.match(pairingIdRegex)).length;
};
const pairingIdValidator = value => (typeof(value) === "string" && value.length > 0 && isOneOrManyPairingIds(value))  ? "" : "The Pairing is invalid";

export const ReprocessPairingForm = (props) => {
    const [errorMsg, setErrorMsg] = React.useState(false);
    const [successMsg, setSuccessMsg] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const { getIdToken, airlineCode } = React.useContext(AppContext);

    const onSubmit = async (data) => {
        console.log("Submitting", data);
        setLoading(true)
        try{
            var idToken = await getIdToken();
            var rawIdToken = idToken.idToken.rawIdToken;
            console.log(data.patternLabel);
            await runPairingSync(airlineCode, "UPDATE", getPatternLabelsList(data.patternLabel), rawIdToken);
            setSuccessMsg("Crew sync completed")
        }catch(e){
            setErrorMsg("Unexpected error. Please try again later")
        }
        setLoading(false)
    };
    
    return <>
        <div className="card-container grid">
                <h3 className="card-title">Reprocess Pairings</h3>
                <div className="card-component">
                    <Form 
                        onSubmit={onSubmit}
                        render={(formRenderProps) => (
                            <FormElement>
                                <fieldset  className={'k-form-fieldset'}>
                                    <legend className={'k-form-legend'}>Force a specific pairing to be re-processed</legend>

                                    <Field
                                        id={'pairingIdentification'}
                                        name={'patternLabel'}
                                        label={'One or many Pairing Identification (Pattern Labels)'}
                                        component={FormFloatingFormInput}
                                        validator={pairingIdValidator}
                                    />

                                    <div className="k-form-buttons">
                                        <SubmitableLoadingButton 
                                            loading={loading} 
                                            disabled={!formRenderProps.allowSubmit}>
                                            Reload
                                        </SubmitableLoadingButton>
                                     </div>
                                </fieldset>
                            </FormElement>
                        )}

                    />
                </div>
                <AlertSuccess
                    message={successMsg}
                    onClose={()=>setSuccessMsg(false)}
                 />
                <AlertFailure 
                    message={errorMsg}
                    onClose={() => setErrorMsg(false)}
                />
            </div>
    </>;



}