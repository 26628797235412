
import * as React from 'react';
import { Navigate } from 'react-router-dom';
import { AirlineFilter } from '../components/AirlineFilter';


import { GetSubscriptionStatusForm } from '../components/Subscription/GetSubscriptionStatusForm';
import { SubscribeToEventForm } from '../components/Subscription/SubscribeToEventForm';
import { UnSubscribeToEventForm } from '../components/Subscription/UnSubscribeToEventForm';

const SubscriptionsPage = () => {
    

    return (
        <div id="SubscriptionsPage" className="subscriptions-page main-content">
            <AirlineFilter rejected="JL">
                <Navigate replace to={{ pathname: "/", }} />
            </AirlineFilter>
            <GetSubscriptionStatusForm />
            <SubscribeToEventForm />
            <UnSubscribeToEventForm />
        </div>
    );
}

export default SubscriptionsPage;

