import React from 'react';

import './App.scss';

import { HashRouter } from 'react-router-dom';


import DrawerRouterContainer from './components/DrawerRouterContainer.js';

import { AppContext } from './AppContext';
import Loading from './components/Loading';
import {setCurrentAirline} from './providers/redux/Actions'
// import jwt_decode from "jwt-decode";

// import { AzureAD } from 'react-aad-msal';
// import { authProvider } from './providers/AuthProvider';
import ReduxStoreProvider from './providers/ReduxStoreProvider';
import store from './providers/redux/Store';
import InitialDataLoader from './providers/InitialDataLoader';
import AppNavigationSwitch from './components/AppNavigationSwitch';
import { loadBidPeriods } from './providers/BidPeriodsProvider';
import { loadAirlineLocks } from './providers/LockStateProvider';
import { AirlineIndicator } from './components/CustomComponents/AirlineIndicator';
import ScrollToTop from './components/ScrollToTop';
import {CONSTANTS} from './constants';

import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider, AuthenticatedTemplate, UnauthenticatedTemplate  } from "@azure/msal-react";
import { LoginPage } from './pages/LoginPage';
import { useMsal } from "@azure/msal-react";

const msalConfig = {
    auth: {
        clientId: CONSTANTS.AAD.CLIENT_ID,
        redirectUri: CONSTANTS.AAD.REDIRECT_URI,
        authority: CONSTANTS.AAD.AUTHORITY_TENNANT,
        postLogoutRedirectUri: CONSTANTS.AAD.REDIRECT_URI
    }
};
const pca = new PublicClientApplication(msalConfig);


const App = () => {
    // const [user, setUser] = React.useState(undefined);
    React.useEffect( () => {
        // console.log("LOCATION HASH:", window.location.hash);
        // var aad = store && store.getState() && store.getState().aad;
        // console.log("aad:", JSON.stringify(aad, null, 4));
        // console.log(" authProvider error:", authProvider.getError());
    });
    
    
    
    console.log("REACT_APP_ENV_NAME", process.env.REACT_APP_ENV_NAME);
    return (
        <div className="App">
            {/* <AzureAD 
                provider={authProvider} 
                forceLogin={true}
                reduxStore={store}
                > */}
            <MsalProvider instance={pca}>
                <AuthenticatedTemplate>
                    <AuthenticatedApp 
                            // contextState={contextState}
                            // onLanguageChange={onLanguageChange}
                            // onProfileChange={onProfileChange}
                            // onAirlineCodeChange={onAirlineCodeChange}
                            // logout={logout}
                        />
                    </AuthenticatedTemplate> 
                <UnauthenticatedTemplate>
                    <LoginPage/>
                </UnauthenticatedTemplate>
                
                {/* <MsalAuthenticationTemplate >
                    <AuthenticatedApp 
                        contextState={contextState}
                        onLanguageChange={onLanguageChange}
                        onProfileChange={onProfileChange}
                        onAirlineCodeChange={onAirlineCodeChange}
                        logout={logout}
                    />
                </MsalAuthenticationTemplate> */}
            </MsalProvider>
            {/* </AzureAD> */}
        </div>
    );
}

function AuthenticatedApp(props)
{

    const [contextState, setContextState] = React.useState({
        localeId: 'en-US',
        firstName: '',
        lastName: '',
        middleName: '',
        email: '',
        phoneNumber: '',
        avatar: null,
        country: '',//countries[33].name,
        isInPublicDirectory: true,
        biography: '',
        teamId: 1,
        airlineCode: CONSTANTS.getActiveAirlines()[0],
        showLoading:false,
    });

    const { instance, accounts } = useMsal();
    
    const logout = React.useCallback(() => {
        instance.logout();
    },[instance]);

    const getTokenSilent = React.useCallback(async () => {
        const baseRequest = {
            scopes: ["openid", "Mail.Read"],
            account: accounts[0]
        };
        // const tokenResponse = await instance.acquireTokenSilent(request).catch(async (error) => {
        //     console.log("Unexpected error pulling MSAL token:", error);
        //     instance.acquireTokenRedirect(request);
        //     return null;
        // });
        try{
            var tokenResponse = await instance.acquireTokenSilent({...baseRequest, forceRefresh:false});
            console.log("Token:", tokenResponse);
            return tokenResponse;
        }catch(error){
            console.error("Unexpected error on accuireToken:", error);
            return await instance.acquireTokenPopup(baseRequest);
        }
    },[instance, accounts]);

    
    const getAccessToken = React.useCallback(async () => {
        var response = await getTokenSilent();
        return response.accessToken;
    },[getTokenSilent]);
    const getIdToken = React.useCallback(async () => {
        var response = await getTokenSilent();
        var idToken = response.idToken;
        // var token = jwt_decode(idToken);
        // if(new Date((token.exp - 30)*1000) < new Date())
        // {
        //     return logout();
        // }
        return {
            idToken:
            {
                rawIdToken: idToken
            }
        };
        // return response.idToken;
    },[getTokenSilent]);


    const onLanguageChange = React.useCallback(
        (event) => { setContextState({...contextState, localeId: event.value.localeId}) },
        [contextState, setContextState]
    );

    const onProfileChange = React.useCallback(
        (event) => {
            setContextState({...contextState, ...event.dataItem});
        },
        [contextState, setContextState]
    );

    const onAirlineCodeChange = React.useCallback(
      async (airlineCode) => {
          try{
            setContextState({...contextState, airlineCode, showLoading:true});
            store.dispatch(setCurrentAirline(airlineCode));
            await loadBidPeriods(getIdToken, airlineCode, store.dispatch);
            await loadAirlineLocks(getIdToken, store.dispatch, airlineCode);
          }finally{
            setContextState({...contextState, airlineCode, showLoading:false});
            window.scrollTo(0, 0);
          }
      },
      [contextState, setContextState, getIdToken]
    );

    const getAccount = () => {
        return accounts[0];
    };
    return (
        <ReduxStoreProvider>
            <AppContext.Provider value={{...contextState, onLanguageChange, onProfileChange, onAirlineCodeChange, logout, getAccessToken, getIdToken, getAccount}}>
                <InitialDataLoader />
                <Loading>
                    <HashRouter>
                    <ScrollToTop/>
                        <DrawerRouterContainer>
                            <AirlineIndicator />
                            <AppNavigationSwitch />
                        </DrawerRouterContainer>
                    </HashRouter>
                </Loading>
            </AppContext.Provider>
        </ReduxStoreProvider>

    );

}

export default App;
