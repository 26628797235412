const MICROHUB_FUNCTION_APP_URL_PREFIX = process.env.REACT_APP_MICROHUB_FUNCTION_APP_URL_PREFIX;
const FRONT_DESK_CORE_URL_PREFIX = process.env.REACT_APP_FRONT_DESK_CORE_URL_PREFIX;

// CONSTANTS object
const MICROHUB_FUNCTION_APP_API_URL_PREFIX = MICROHUB_FUNCTION_APP_URL_PREFIX + '/api/';
const FRONT_DESK_CORE_CREWMANAGER_API_URL_PREFIX = FRONT_DESK_CORE_URL_PREFIX + '/api/v1/CrewManager/';

const AIRLINES_BY_CODE = {
    JL: "Japan Airlines",
    HA: "Hawaiian Airlines",
    NK: "Spirit Airlines",
    AR: "Aerolineas Argentina",
    AA: "American Airlines",
    FX: "FedEx",
    W6: "Wizz Air Airlines",
    YX: "Republic Airways",

}
const getActiveAirlines = () => (process.env.REACT_APP_ACTIVE_AIRLINES || "JL,HA").split(",");
const ACTIVE_AIRLINES= getActiveAirlines().map(airlineCode => ({
    code: airlineCode,
    name: AIRLINES_BY_CODE[airlineCode]
}))

// const ACTIVE_AIRLINES =  [
//     { code: "AA", name: "American Airlines"},
//     { code: "HA", name: "Hawaiian Airlines"},
//     { code: "JL", name: "Japan Airlines"},
// ];
const SECTIONS_BY_AIRLINE = {
    'JL' : ['Dashboard','Pairings', 'Bid Periods', 'Crews','Reservations','Hotels','Transports','Subscriptions','Outages'/*, 'Walking Times'*/, 'General Settings'],
    'HA' : ['Dashboard','Pairings', 'Bid Periods','Crews', 'General Settings'],
    'NK' : ['Dashboard','Pairings', 'Bid Periods','Crews', 'General Settings'],
    'AA' : ['Dashboard','Pairings', 'Bid Periods','Crews', 'General Settings'],
    'FX' : ['Dashboard', 'Bid Periods','General Settings'],
    'W6' : ['Dashboard', 'Bid Periods','General Settings'],
    'YX' : ['Dashboard', 'Bid Periods','General Settings'],
};

const CREW_POSITIONS_JAL = ["AT","ATCH","ATJ","ATJK","ATJL","ATJR","ATV","ATW","ATX","ATY","ATZ","CAP","CH","FO","IC","INKR","LCD","OAT","OATA","OATW","OBS","OJCA","OTC","SFT","TRCA","TRN"];
const CREW_CHANGE_TYPE = [
    {id: "ADD", text: "New Crews"},
    {id: "MODIFY", text: "Update Crews"},
    {id: "DELETE", text: "Delete Crews"},
]
const PAIRINGS_IDENTIFICATION_TYPE = [
    { label: 'Pattern Label', value: 'pattern_label'},
    { label: 'Trip Number', value: 'trip_number'},
];

const PAIRINGS_EVENT_TYPES = [
    {id: "INITIAL_LOAD", text: "Initial Load"},
    {id: "ROSTER_LOAD", text: "Roster Load"},
    {id: "UPDATE", text: "Update"},
];

const PAIRING_EVENT_TYPES_BY_AIRLINE = {
    'JL' : ['UPDATE', 'ROSTER_LOAD'],
    'HA' : ['ROSTER_LOAD'],
    'NK' : ['ROSTER_LOAD'],
}
const CREWMANAGER_SUBSCRIPTION_EVENT_TYPES = [
    {id: "CrewChanged", text: "CrewChanged"},
    {id: "CrewPairingChanged", text: "CrewPairingChanged"},
    {id: "CrewOnPairingChanged", text: "CrewOnPairingChanged"},
];

const AVAILABLE_QUEUES = [
    'booking-wizard-store',
    'crew-get-details-buffer',
    'crew-get-details',
    'crew-store',
    'hotels-sync',
    // 'pairings-aa-inbound', // This is added dynamically for AA ONly.
    'pairings-drop',
    'pairings-get-details-buffer',
    'pairings-get-details',
    'pairings-store',
    'providers-sync',
    'reservations-get-details',
    'reservations-send-to-crewmanager',
];

if(getActiveAirlines().indexOf("AA") > -1)
{
    AVAILABLE_QUEUES.push('pairings-aa-inbound');
}
export const CONSTANTS = {
    getActiveAirlines,
    ACTIVE_AIRLINES,
    AIRLINES_BY_CODE,
    SECTIONS_BY_AIRLINE,
    AAD: {
        CLIENT_ID: process.env.REACT_APP_AAD_CLIENT_ID,
        AUTHORITY_TENNANT: process.env.REACT_APP_AAD_AUTHORITY_TENNANT,
        REDIRECT_URI: process.env.REACT_APP_AAD_REDIRECT_URI,
    },
    FRONT_DESK_CORE:{
        SUGGESTED_ENDPOINTS:[
            FRONT_DESK_CORE_CREWMANAGER_API_URL_PREFIX + "PairingEvent?airlineCode=JL",
            FRONT_DESK_CORE_CREWMANAGER_API_URL_PREFIX + "CrewEvent?airlineCode=JL",
        ]
    },
    MICROHUB_ENDPOINTS: {
        URL_PREFIX: MICROHUB_FUNCTION_APP_URL_PREFIX,
        API_URL_PREFIX: MICROHUB_FUNCTION_APP_API_URL_PREFIX,
        CREW: {
            INITIAL_IMPORT: MICROHUB_FUNCTION_APP_API_URL_PREFIX + 'CrewsInitialImportManual',
            SYNC: MICROHUB_FUNCTION_APP_API_URL_PREFIX + 'CrewSyncManual',
            LOAD_FILE: MICROHUB_FUNCTION_APP_API_URL_PREFIX + 'CrewsLoadFileManual',
        },
        HEALTH: {
            CHECK: MICROHUB_FUNCTION_APP_API_URL_PREFIX + 'HealthCheck'
        },
        HOTEL: {
            SYNC: MICROHUB_FUNCTION_APP_API_URL_PREFIX + 'HotelSyncManual'
        },
        PAIRING: {
            MONTHLY_LOAD: MICROHUB_FUNCTION_APP_API_URL_PREFIX + 'PairingMonthlyLoadManual',
            BID_PERIOD_LOAD: MICROHUB_FUNCTION_APP_API_URL_PREFIX + 'PairingBidPeriodLoadManual',
            // FAS_LOAD: "http://localhost:7071/api/PairingAAFasLoadManual",//MICROHUB_FUNCTION_APP_API_URL_PREFIX + 'PairingAAFasLoadManual',
            FAS_LOAD: MICROHUB_FUNCTION_APP_API_URL_PREFIX + 'PairingAAFasLoadManual',
            SYNC: MICROHUB_FUNCTION_APP_API_URL_PREFIX + 'PairingSyncManual',
            UPDATE: MICROHUB_FUNCTION_APP_API_URL_PREFIX + 'PairingProcessUpdates',
            FAS_EXPORT: MICROHUB_FUNCTION_APP_API_URL_PREFIX + 'PairingAAExportFiles',
            // FAS_EXPORT: "http://localhost:7071/api/PairingAAExportFiles"
        },
        QUEUES: {
            GET_STATUS: MICROHUB_FUNCTION_APP_API_URL_PREFIX + "GetQueueDetailsManual",
            SET_STATUS: MICROHUB_FUNCTION_APP_API_URL_PREFIX +"SetQueueStatusManual",
        },
        PROVIDER: {
            SYNC: MICROHUB_FUNCTION_APP_API_URL_PREFIX + 'TransportCompaniesSyncManual'
        },
        RESERVATION: {
            SYNC: MICROHUB_FUNCTION_APP_API_URL_PREFIX + 'ReservationsSyncManual'
        },
        SUBSCRIPTION: {
            GET_STATUS: MICROHUB_FUNCTION_APP_API_URL_PREFIX + 'SubscriptionsGetStatus',
            SUBSCRIBE: MICROHUB_FUNCTION_APP_API_URL_PREFIX + 'SubscriptionsSubscribeEvent',
            UNSUBSCRIBE: MICROHUB_FUNCTION_APP_API_URL_PREFIX + 'SubscriptionsUnsubscribeEvent',
            RENEWER: MICROHUB_FUNCTION_APP_API_URL_PREFIX + 'SubscriptionsRenewer',
        },
        BID_PERIODS:{
            GET:  MICROHUB_FUNCTION_APP_API_URL_PREFIX + 'BidPeriodsGet',
            UPDATE: MICROHUB_FUNCTION_APP_API_URL_PREFIX + 'BidPeriodsUpdate',
            DELETE: MICROHUB_FUNCTION_APP_API_URL_PREFIX + 'BidPeriodsDelete',
        },
        LOCKS: {
            GET : MICROHUB_FUNCTION_APP_API_URL_PREFIX + 'GetLocks',
            SET_PAIRINGS_HOLD: MICROHUB_FUNCTION_APP_API_URL_PREFIX + 'LockPairingsHoldExecution',
            UNLOCK_PAIRINGS_SYNC: MICROHUB_FUNCTION_APP_API_URL_PREFIX + 'UnlockPairingsSyncExecution',
            UNLOCK_CREW_SYNC: MICROHUB_FUNCTION_APP_API_URL_PREFIX + 'UnlockCrewSyncExecution',
        },
        FEATURES_SETTINGS:{
            GET : MICROHUB_FUNCTION_APP_API_URL_PREFIX + 'GetFeatureSettings',
            SET : MICROHUB_FUNCTION_APP_API_URL_PREFIX + 'SetFeatureSettings',
        },
        BLOB_STORAGE:{
            GET_FILE: MICROHUB_FUNCTION_APP_API_URL_PREFIX + 'HttpGetFile',
            // GET_FILE: 'http://localhost:7071/api/' + 'HttpGetFile'
            // GET_FILE: "http://localhost:7071/api/HttpGetFile"
            
        }
    },
    CREWS: {
        POSITIONS: {
            JAL:CREW_POSITIONS_JAL,
        },
        EVENT_TYPES:CREW_CHANGE_TYPE,/*[
            {id: "ADD", text: "New Crew"},
            {id: "MODIFY", text: "Modify Crew"},
            {id: "DELETE", text: "Drop Crew"},
        ]*/
    },
    PAIRINGS:{
        IDENTIFICATION_TYPES:PAIRINGS_IDENTIFICATION_TYPE,
        EVENT_TYPES: PAIRINGS_EVENT_TYPES,
        BY_AIRLINE: PAIRING_EVENT_TYPES_BY_AIRLINE,
    },

    CREWMANAGER:{
        SUBSCRIPTIONS:{
            EVENT_TYPES:CREWMANAGER_SUBSCRIPTION_EVENT_TYPES,
        }

    },

    HTML_COLORS:{
        backgroundColor: "#D4D6E4",
        infoBackground: "#151950",
        reactColor: "#53d2fa",
        linkColor: "#71A6FF",
        white: "#FFFFFF",
        tigerTeam: "#FF6358",
        lemonTeam: "#FFD246",
        organicTeam: "#55AB1D",
        oceanTeam: "#28B4C8",
        tealColor: "#0cc2aa",
        tealColorLight: "#1cd2ba",
        tealColorDark: "#00b29a",
        darkblueColor: "#2b3a49",
        darkgreyColor: "#e0e0e0",
        textDanger: "#ed5564",
    },
    QUEUES: AVAILABLE_QUEUES,
    QUEUE_STATUSES: {
        Active: "Active",
        ReceiveDisabled: "Buffering",
        SendDisabled: "Read Only (Invalid)",
        Disabled: "Disabled"
    }

}