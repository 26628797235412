import * as React from 'react';
import Grid from '@mui/material/Grid';
import { QueueInfoGadget } from './QueueInfoGadget';
// import { Button } from '@progress/kendo-react-buttons';
// import {Button} from '@mui/material';

import { AppContext } from '../../AppContext';
import { loadQueuesState } from '../../providers/QueueStateProvider';
import { useDispatch } from 'react-redux';
import {Refresh as RefreshIcon} from '@mui/icons-material';
import LoadingButton from '../LoadingButton';

export const timeRanges = [
    { label: 'Last 24 hs', value: '24'},
    // { label: 'Last 12 hs', value: '12'},
    { label: 'Last 6 hs', value: '6'},
    // { label: 'Last 3 hs', value: '3'},
    { label: 'Last hour', value: '1'},
];

export const QueuesPanel = ({title, queues}) => {
    const { getIdToken } = React.useContext(AppContext);
    const dispatch = useDispatch();    
    
    const refreshQueues = () => {
        loadQueuesState(getIdToken, dispatch);
    }

    const gridSizeSm= parseInt(Math.floor(12 / Math.max(1,queues.length)));
    return (
        <div id="QueuesPanel" className="subscriptions-page main-content">
            <div className="card-container grid">
                <h3 className="card-title">{title}</h3>
                {/* <div className="card-buttons">
                    <RadioGroup 
                        data={timeRanges}
                        defaultValue="6"
                        layout="horizontal"
                    />
                </div> */}
                <div className="card-ranges">
                    <LoadingButton onClick={refreshQueues}><RefreshIcon/></LoadingButton>
                </div>
                <div className="card-component">
                    <Grid container spacing={3}>
                        {queues.map((queueDataItem, i) => (
                            <Grid item xs={12} sm={gridSizeSm} key={i}>
                                <QueueInfoGadget 
                                    name={queueDataItem.title}
                                    code={queueDataItem.code}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </div>
            </div>
        </div>
    );

}