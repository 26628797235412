import * as React from 'react';
import { QueueStatusManager } from '../Queues/QueueStatusManager';


export const HandleCrewsBufferForm = ({isActive = true}) => {

    return <QueueStatusManager
        title="Handle Crews Buffer"
        legend="Handle the buffer for processing crew events"
        code="crew-get-details-buffer"
    />
}