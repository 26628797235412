import * as React from 'react';
import * as PropTypes from 'prop-types';
import { useSelector, useDispatch} from 'react-redux'
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { AppContext } from './../AppContext'
import headerBg from '../assets/header-bg.png';
import { Menu, MenuItem } from '@progress/kendo-react-layout';
import { CONSTANTS } from '../constants';
import { setCurrentAirline } from '../providers/redux/Actions';

export const Header = (props) => {
    const {  onAirlineCodeChange, airlineCode } = React.useContext(AppContext);
    const dispatch = useDispatch();
    const initialAirlineCode = useSelector(state => state.currentAirline.airlineCode);

    if(airlineCode !== initialAirlineCode){
        // onAirlineCodeChange(initialAirlineCode);
        dispatch(setCurrentAirline(airlineCode));
    }

    const { onButtonClick, page } = props;
    const {
        avatar,
        logout,
        getAccount
    } = React.useContext(AppContext);

    const imgRef = React.useRef(null);
    const hasImage = false && avatar && avatar.length > 0;

    React.useEffect(
        () => {
            if (hasImage) {
                var reader = new FileReader();

                reader.onload = function(e) {
                    imgRef.current.setAttribute('src', e.target.result)
                }

                reader.readAsDataURL(avatar[0].getRawFile());
            }
        },
        [avatar, hasImage]
    );

    // const drawAvatar = () => {
    //     if(hasImage){
    //         return (
    //             <Avatar type={'image'} shape={'circle'}>{
    //                 <img ref={imgRef} src={'#'} alt={'User Avatar'} /> 
    //             }
    //             </Avatar>)
    //     }else{
    //         return (<AccountCircle fontSize="large" style={{fill:"white"}}/>)
    //     }
    // };

    const doLogout = () => {
        console.log("Loging out");
        logout();
    }

    const handleMenuSelection = (index) => {
        // console.log(index.itemId )
        if(index.itemId === "0_0"){
            doLogout();
        }
    }   

    const handleAirlineCodeChange = (e) => {
        onAirlineCodeChange(e.target.value.code);
    }
    const accountName = getAccount().name;
    // console.log("Header Account:", accountName );
    return (
        <header className="header" style={{ backgroundImage: `url(${headerBg})` }}>
            <div className="nav-container">
                <div className="menu-button">
                    <span className={'k-icon hamburger-icon'} onClick={onButtonClick}/>
                </div>

                <div className="title">
                    {/* <h1>{localizationService.toLanguageString('custom.warehouse')}</h1> */}
                    <h1>MicroHUB UI</h1>
                    <span className="vl"></span>
                    <h2>{page}</h2>
                </div>
                <div className="settings">
                    <span>Current Airline</span>
                    <DropDownList
                    style={{maxWidth:200}}
                        textField={'name'}
                        dataItemKey={'code'}
                        data={CONSTANTS.ACTIVE_AIRLINES}
                        //value={airlineCodeOk}
                        defaultValue={CONSTANTS.ACTIVE_AIRLINES.filter(a => a.code === airlineCode)[0]}
                        onChange={handleAirlineCodeChange}
                    />
                </div>
                <div id="user-menu"  className="settings">
                    {/* &nbsp;<span>{accountName}</span> */}
                    <Menu onSelect={handleMenuSelection}>
                        <MenuItem 
                            icon={null} 
                            // render={drawAvatar}
                            text={accountName}
                            style={{color:"#FFFFFF"}}
                            >
                            {/* <MenuItem render={drawCurrentAirline} /> */}
                            <MenuItem text="Logout" key="Sarasa" />
                        </MenuItem>
                    </Menu>
                </div>
            </div>
        </header>
    );
}

Header.displayName = 'Header';
Header.propTypes = {
    page: PropTypes.string,
    onButtonClick: PropTypes.func
};
