
import * as React from 'react';

import { CrewQueuesMonitor } from '../components/Crews/CrewQueuesMonitor';
import { HotelQueuesMonitor } from '../components/Hotels/HotelQueuesMonitor';
import { PairingQueuesMonitor } from '../components/Pairings/PairingQueuesMonitor';
import { ReservationQueuesMonitor } from '../components/Reservations/ReservationQueuesMonitor';
import { TransportQueuesMonitor } from '../components/Transport/TransportQueuesMonitor';
import {AirlineFilter} from '../components/AirlineFilter';
import { BookingWizardMonitor } from '../components/BookingWizard/BookingWizardMonitor';

const DashboardPage = () => {
    

    return (
        <div id="DashboardPage" className="dashboard-page main-content">
            <PairingQueuesMonitor/>
            <AirlineFilter rejected={["FX","W6"]} >
                <CrewQueuesMonitor/>
            </AirlineFilter>
            <AirlineFilter allowed={["JL"]}>
                <ReservationQueuesMonitor/>
                <TransportQueuesMonitor />
                <HotelQueuesMonitor />
            </AirlineFilter>
            <BookingWizardMonitor />
            
        </div>
    );
}

export default DashboardPage;

