import React from 'react'
import { useDispatch } from 'react-redux';
import { AppContext } from '../AppContext';
import { loadBidPeriods } from './BidPeriodsProvider';
import { loadFeatureSettingsAsync } from './FeatureSettingsProvider';
import { loadAirlineLocks } from './LockStateProvider';
import { loadQueuesState } from './QueueStateProvider';


export default function InitialDataLoader({children}){
    const { getIdToken, airlineCode } = React.useContext(AppContext);
    const dispatch = useDispatch();
    React.useEffect( () => {
        async function LoadInitialDataAsync(){
            await Promise.allSettled([
                loadQueuesState(getIdToken, dispatch),
                loadBidPeriods(getIdToken, airlineCode, dispatch),
                loadAirlineLocks(getIdToken, dispatch, airlineCode),
                loadFeatureSettingsAsync(getIdToken, dispatch),
            ]);
        }
        LoadInitialDataAsync();
    });
    return (<>{ children }</>);
}