import * as React from 'react';
import { AppContext } from '../../AppContext';
import { QueuesPanel } from '../Queues/QueuesPanel';

export const timeRanges = [
    { label: 'Last 24 hs', value: '24'},
    // { label: 'Last 12 hs', value: '12'},
    { label: 'Last 6 hs', value: '6'},
    // { label: 'Last 3 hs', value: '3'},
    { label: 'Last hour', value: '1'},
];

export const PairingQueuesMonitor = (props) => {
    var {airlineCode } = React.useContext(AppContext);

    var queues = [];

    if(airlineCode ==="JL")
    {
        queues.push({
            title:'Get Details Buffer',
            code:'pairings-get-details-buffer'
        });
    }
    if(airlineCode ==="AA")
    {
        queues.push({
            title:'AA Inbound',
            code:'pairings-aa-inbound'
        });
    }
    queues.push({
        title:'Get Details',
        code:'pairings-get-details'
    });
    queues.push({
        title:'Store',
        code:'pairings-store'
    });
    queues.push({
        title:'Drop',
        code:'pairings-drop'
    });

    return (
        <QueuesPanel
            title={'Pairings Queues'}
            queues={queues}
        />
    )

    

}