import * as React from 'react';
import { QueuesPanel } from '../Queues/QueuesPanel';

export const timeRanges = [
    { label: 'Last 24 hs', value: '24'},
    // { label: 'Last 12 hs', value: '12'},
    { label: 'Last 6 hs', value: '6'},
    // { label: 'Last 3 hs', value: '3'},
    { label: 'Last hour', value: '1'},
];

export const ReservationQueuesMonitor = (props) => {
    var queues = [
        {
            title:'Get Details',
            code:'reservations-get-details'
        },
        {
            title:'Send to CrewManager',
            code:'reservations-send-to-crewmanager'
        },
    ]
    return (
        <QueuesPanel
            title={'Reservations Queues'}
            queues={queues}
        />
    )
}