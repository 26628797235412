import * as React from 'react';
// import { Button } from '@progress/kendo-react-buttons';
import { AppContext } from '../../AppContext';
import { useDispatch, useSelector } from 'react-redux';
import { Form, FormElement } from '@progress/kendo-react-form';
import LoadingButton from '../LoadingButton';
import AlertSuccess from '../AlertSuccess';
import AlertFailure from '../AlertFailure';
import { loadAirlineLocks, unlockAirlineForPairingsSync } from '../../providers/LockStateProvider';
import { setCurrentAirlineLocks } from '../../providers/redux/Actions';
import {Refresh as RefreshIcon} from '@mui/icons-material';

export const ReleasePairingSyncLockForm = () => {
    const { getIdToken, airlineCode } = React.useContext(AppContext);
    const dispatch = useDispatch();
    const [errorMsg, setErrorMsg] = React.useState(false);
    const [successMsg, setSuccessMsg] = React.useState(false);

    const currentAirline = useSelector( state => state.currentAirline && state.currentAirline) ;

    const isSyncingPairings = (currentAirline && currentAirline.locks && currentAirline.locks.pairingsSyncLock) || false;
    console.log("isSyncingPairings", isSyncingPairings)
    const refreshLocks = async () => {
        await loadAirlineLocks(getIdToken, dispatch, airlineCode);
    }

    const onSubmit = async () => {
        try{
            setSuccessMsg(false);
            await unlockAirlineForPairingsSync(getIdToken, airlineCode);
            dispatch(setCurrentAirlineLocks({...currentAirline.locks, pairingsSyncLock: false}));
            setSuccessMsg("The pairing sync lock has been released");
        }catch(e){
            setErrorMsg("Unexpected error. Please try again later")
        }
    }

    const stateTitle = isSyncingPairings ? "Release current lock" : "There is no running process"
    return (
        <div id="SubscriptionsPage" className="subscriptions-page main-content">
            <div className="card-container grid">
                <h3 className="card-title">Release lock for current pairing update</h3>
                <div className="card-ranges">
                    <LoadingButton onClick={refreshLocks}><RefreshIcon/></LoadingButton>

                </div>
                <div className="card-component">
                    <Form 
                        // onSubmit={onSubmit}
                        render={(formRenderProps) => (
                            <FormElement>
                                <fieldset  className={'k-form-fieldset'}>
                                    <legend className={'k-form-legend'}>Release the lock for a running process.</legend>
                                    <h4>{stateTitle}</h4>
                                    { isSyncingPairings && <div className="k-form-buttons">
                                        <LoadingButton onClick={onSubmit}>Release lock</LoadingButton>
                                    </div>}
                                </fieldset>
                            </FormElement>

                        )}

                    />
                </div>
                <AlertSuccess
                    message={successMsg}
                    onClose={()=>setSuccessMsg(false)}
                 />
                <AlertFailure
                    message={errorMsg}
                    onClose={() => setErrorMsg(false)}
                />
            </div>
        </div>
    );

}