
import * as React from 'react';

import { FeatureSettingsForm } from '../components/GeneralSettings/FeatureSettingsForm';

const GeneralSettingsPage = () => {


    return (
        <div id="GeneralSettingsPage" className="general-settings-page main-content">
            <FeatureSettingsForm />
        </div>
    );
}

export default GeneralSettingsPage;

