import * as React from "react";

import { Calendar, DatePicker } from "@progress/kendo-react-dateinputs";

class CustomCalendar extends React.Component {
  render() {
    return (
      <Calendar
        bottomView="year"
        topView="year"
        value={this.props.value}
        onChange={this.props.onChange}
      />
    );
  }
}


export function MonthPicker(props){

    return <DatePicker
                format={'MM/yyyy'}
                calendar={CustomCalendar}
                {...props}
            />
}