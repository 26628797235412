import axios from 'axios';
import {CONSTANTS} from '../constants'


function getUrlWithParams(baseUrl, urlParams){
    var url = baseUrl;
    if(urlParams){
        if(url.indexOf("?") === -1){
            url += "?";
        }
        if(urlParams != null && Array.isArray(Object.keys(urlParams)) && Object.keys(urlParams).length > 0){
            Object.keys(urlParams).forEach( key => url += `&${key}=${encodeURIComponent(urlParams[key])}` )
        }
    }
    return url;
}

export async function performHttpGetOperationOnMicrohub(baseUrl, urlParams, idToken){
    const url = getUrlWithParams(baseUrl, urlParams);
    var response = await axios({
        method: 'get',
        url,
        headers:{
            'Authorization': 'Bearer ' + idToken,
        }
    });
    return response.data;
}

export async function performHttpGetOperationOnMicrohubAndGetABlob(baseUrl, urlParams, idToken){
    const url = getUrlWithParams(baseUrl, urlParams);
    var response = await axios({
        method: 'get',
        url,
        responseType: 'blob',
        headers:{
            'Authorization': 'Bearer ' + idToken,
        }
    });
    return response.data;
}

export async function performHttpJsonPostOperationOnMicrohub(baseUrl, urlParams, body, idToken){
    const url = getUrlWithParams(baseUrl, urlParams);
    console.log("url", url);
        var params = {
                method: 'post',
                url,
                data:JSON.stringify(body, null, 4),
                headers:{
                    'Authorization': 'Bearer ' + idToken,
                    'Content-Type':  'application/json'
                }
            }
        var response = await axios(params);
        return response.data;
}

export async function performHttpTextPostOperationOnMicrohub(baseUrl, urlParams, body, idToken){
    const url = getUrlWithParams(baseUrl, urlParams);
    console.log("url", url);
        var params = {
                method: 'post',
                url,
                data: body,
                headers:{
                    'Authorization': 'Bearer ' + idToken,
                    // 'Content-Type':  'text/plain',
                    'Content-Type': 'multipart/form-data',
                }
            }
        var response = await axios(params);
        console.log("HTTP Request finished!")
        return response.data;
}

// CREWS
export const runCrewInitialImport = async (airlineCode, rank, changeType, idToken) => {
    return await performHttpGetOperationOnMicrohub(CONSTANTS.MICROHUB_ENDPOINTS.CREW.INITIAL_IMPORT, {AirlineCode: airlineCode, Rank:rank, ChangeType:changeType}, idToken);
};

export const runCrewSync = async (airlineCode, changeType, crewIds, idToken) => {
    return await performHttpJsonPostOperationOnMicrohub(CONSTANTS.MICROHUB_ENDPOINTS.CREW.SYNC, {AirlineCode: airlineCode, ChangeType:changeType}, crewIds, idToken);
};

export const runCrewFileLoad = async (airlineCode, eventType, filename, fasContent, idToken) => {
    return await performHttpTextPostOperationOnMicrohub(CONSTANTS.MICROHUB_ENDPOINTS.CREW.LOAD_FILE, {AirlineCode: airlineCode, FileName:filename, EventType: eventType/*, RunAsync:"false"*/}, fasContent, idToken);
};
    

// HEALTH
export const runHealthCheck = async ( idToken) => {
    return await performHttpGetOperationOnMicrohub(CONSTANTS.MICROHUB_ENDPOINTS.HEALTH.CHECK, null, idToken);
};

// HOTELS
export const runHotelSync = async (airlineCode, hotelIds, idToken) => {
    return await performHttpJsonPostOperationOnMicrohub(CONSTANTS.MICROHUB_ENDPOINTS.HOTEL.SYNC, {AirlineCode: airlineCode}, hotelIds, idToken);
};

// PAIRINGS
export const runPairingMonthlyLoad = async (airlineCode, year, month, eventType, idToken) => {
    return await performHttpGetOperationOnMicrohub(CONSTANTS.MICROHUB_ENDPOINTS.PAIRING.MONTHLY_LOAD, {AirlineCode: airlineCode, Year:year, Month:month, EventType: eventType}, idToken);
};

export const runPairingBidPeriodLoad = async (airlineCode, bidPeriodId, eventType, idToken) => {
    return await performHttpGetOperationOnMicrohub(CONSTANTS.MICROHUB_ENDPOINTS.PAIRING.BID_PERIOD_LOAD, {AirlineCode: airlineCode, BidPeriodId:bidPeriodId, EventType: eventType}, idToken);
};

export const runPairingFasLoad = async (airlineCode, eventType, filename, fasContent, idToken) => {
return await performHttpTextPostOperationOnMicrohub(CONSTANTS.MICROHUB_ENDPOINTS.PAIRING.FAS_LOAD, {AirlineCode: airlineCode, FileName:filename, EventType: eventType/*, RunAsync:"false"*/}, fasContent, idToken);
};

export const runPairingSync = async (airlineCode, eventType, pairingIds, idToken) => {
    return await performHttpJsonPostOperationOnMicrohub(CONSTANTS.MICROHUB_ENDPOINTS.PAIRING.SYNC, {AirlineCode: airlineCode, EventType: eventType}, pairingIds, idToken);
};

export const runForcePairingsUpdateSync = async (airlineCode, idToken) => {
    return await performHttpGetOperationOnMicrohub(CONSTANTS.MICROHUB_ENDPOINTS.PAIRING.UPDATE, {AirlineCode: airlineCode}, idToken);
};

export const runAAFasExportHotelFiles = async (airlineCode, bidPeriodId, idToken) =>{
    return await performHttpGetOperationOnMicrohub(CONSTANTS.MICROHUB_ENDPOINTS.PAIRING.FAS_EXPORT, {AirlineCode: airlineCode, BidPeriodId: bidPeriodId}, idToken);
}

// BID PERIODS
export const getAirlineBidPeriods = async (airlineCode, idToken) => {
    return await performHttpGetOperationOnMicrohub(CONSTANTS.MICROHUB_ENDPOINTS.BID_PERIODS.GET, {AirlineCode: airlineCode}, idToken);
};

export const updateAirlineBidPeriod = async (airlineCode, bidPeriod, idToken) => {
    return await performHttpJsonPostOperationOnMicrohub(CONSTANTS.MICROHUB_ENDPOINTS.BID_PERIODS.UPDATE, {AirlineCode: airlineCode}, bidPeriod, idToken);
};

export const deleteAirlineBidPeriod = async (airlineCode, bidPeriodId, idToken) => {
    return await performHttpGetOperationOnMicrohub(CONSTANTS.MICROHUB_ENDPOINTS.BID_PERIODS.DELETE, {AirlineCode: airlineCode, BidPeriodId:bidPeriodId}, idToken);
};


// PROVIDERS
export const runProviderSync = async (airlineCode, providerIds, idToken) => {
    return await performHttpJsonPostOperationOnMicrohub(CONSTANTS.MICROHUB_ENDPOINTS.PROVIDER.SYNC, {AirlineCode: airlineCode}, providerIds, idToken);
};

// QUEUES
export const getQueuesDetails = async (queueNames, idToken) => {
    return await performHttpJsonPostOperationOnMicrohub(CONSTANTS.MICROHUB_ENDPOINTS.QUEUES.GET_STATUS, null, queueNames, idToken);
};

export const setQueueStatus = async (queueName, canSend, canReceive, idToken) => {
    return await performHttpGetOperationOnMicrohub(CONSTANTS.MICROHUB_ENDPOINTS.QUEUES.SET_STATUS, {QueueName:queueName, CanSend:canSend, CanReceive: canReceive}, idToken);
};

// RESERVATIONS
export const runReservationSync = async (airlineCode, isPatternLabel=false, runAsync=false, pairingIds,  idToken) => {
    return await performHttpJsonPostOperationOnMicrohub(CONSTANTS.MICROHUB_ENDPOINTS.RESERVATION.SYNC, {AirlineCode: airlineCode, IsPatternLabel:isPatternLabel, RunAsync: runAsync}, pairingIds, idToken);
};

// SUBSCRIPTION

export const setSubscriptionWebhook = async (eventName, webhook, idToken) => {
    return await performHttpGetOperationOnMicrohub(CONSTANTS.MICROHUB_ENDPOINTS.SUBSCRIPTION.SUBSCRIBE, {event: eventName, url:webhook}, idToken);
};

export const getSubscriptionStatus = async (eventName, idToken) => {
    return await performHttpGetOperationOnMicrohub(CONSTANTS.MICROHUB_ENDPOINTS.SUBSCRIPTION.GET_STATUS, {event: eventName}, idToken);
};

export const delSubscription = async (eventName, idToken) => {
    return await performHttpGetOperationOnMicrohub(CONSTANTS.MICROHUB_ENDPOINTS.SUBSCRIPTION.UNSUBSCRIBE, {event: eventName}, idToken);
};

// LOCKS
export const getAirlineLocks = async (airlineCode, idToken) => {
    return await performHttpGetOperationOnMicrohub(CONSTANTS.MICROHUB_ENDPOINTS.LOCKS.GET, {AirlineCode: airlineCode}, idToken);
};

export const setAirlineLockForPairingsExecutionHold = async (airlineCode, lock, idToken) => {
    return await performHttpJsonPostOperationOnMicrohub(CONSTANTS.MICROHUB_ENDPOINTS.LOCKS.SET_PAIRINGS_HOLD, {AirlineCode:airlineCode, Lock:lock}, {}, idToken);
}

export const setAirlineUnlockForPairingsSync = async (airlineCode, idToken) => {
    return await performHttpJsonPostOperationOnMicrohub(CONSTANTS.MICROHUB_ENDPOINTS.LOCKS.UNLOCK_PAIRINGS_SYNC, {AirlineCode:airlineCode}, {}, idToken);
}

export const setAirlineUnlockForCrewSync = async (airlineCode, idToken) => {
    return await performHttpJsonPostOperationOnMicrohub(CONSTANTS.MICROHUB_ENDPOINTS.LOCKS.UNLOCK_CREW_SYNC, {AirlineCode:airlineCode}, {}, idToken);
}
// Features
export const getFeatureSettings = async (idToken) => {
    return await performHttpGetOperationOnMicrohub(CONSTANTS.MICROHUB_ENDPOINTS.FEATURES_SETTINGS.GET, {}, idToken);
};

export const setFeatureSettingsAsync = async (features, idToken) => {
    return await performHttpJsonPostOperationOnMicrohub(CONSTANTS.MICROHUB_ENDPOINTS.FEATURES_SETTINGS.SET, {}, features, idToken);
}

// Files
export const getFileFromBlobStorage = async (container, path, idToken) => {
    return await performHttpGetOperationOnMicrohubAndGetABlob(CONSTANTS.MICROHUB_ENDPOINTS.BLOB_STORAGE.GET_FILE, {Container:container, Path:path}, idToken);
};
