import * as React from 'react';
import { Form, Field, FormElement } from '@progress/kendo-react-form';
import { FormFloatingFormInput, FormRadioGroup } from '../form-components';
import { Button } from '@progress/kendo-react-buttons';
import { CONSTANTS } from '../../constants';




const pairingIdentificationType = CONSTANTS.PAIRINGS.IDENTIFICATION_TYPES;


const pairingIdRegex = /^[0-9a-zA-Z-_]+$/;
const pairingIdValidator = (value) => typeof(value) === "string" ?  value.match(pairingIdRegex) ? "" : "The Pairing is invalid" : "";

export const DropPairingForm = (props) => {

    const onSubmit = () => {};
    
    
    console.log(5)
    return <>
        <div className="card-container grid">
                <h3 className="card-title">Drop Single Pairing</h3>
                <div className="card-component">
                    <Form 
                        onSubmit={onSubmit}
                        render={(formRenderProps) => (
                            <FormElement>
                                <fieldset  className={'k-form-fieldset'}>
                                    <legend className={'k-form-legend'}>Force a specific pairing to be marked as deleted</legend>

                                    <Field
                                        id={'pairingIdentification'}
                                        name={'pairing'}
                                        label={'Pairing Identification'}
                                        component={FormFloatingFormInput}
                                        validator={pairingIdValidator}
                                    />


                                    <Field
                                        id={'pairingIdentificationType'}
                                        name={'pairingIdentificationType'}
                                        label={'Is a pattern label or a trip number?'}
                                        layout={'horizontal'}
                                        defaultValue={'pattern_label'}
                                        component={FormRadioGroup}
                                        data={pairingIdentificationType}
                                    />

                                     <div className="k-form-buttons">
                                        <Button
                                            primary={true}
                                            type={'submit'}
                                            disabled={!formRenderProps.allowSubmit}>
                                            Drop Pairing
                                        </Button>
                                     </div>
                                </fieldset>
                            </FormElement>

                        )}

                    />
                </div>
            </div>
    </>;



}