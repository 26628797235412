import * as React from 'react';
// import { AppContext } from '../../AppContext';
import AlertSuccess from '../AlertSuccess';
import AlertFailure from '../AlertFailure';
// import { runForcePairingsUpdateSync } from '../../providers/MicrohubApiProvider';


export const DefaultWalkingTimesForm = (props) => {
    const [errorMsg, setErrorMsg] = React.useState(false);
    const [successMsg, setSuccessMsg] = React.useState(false);
    // const [loading, setLoading] = React.useState(false);
    // const { getIdToken, airlineCode } = React.useContext(AppContext);
    
    // const onSubmit = async () => {
    //     console.log("Submitting");
    //     setLoading(true)
    //     try{
    //         var idToken = await getIdToken();
    //         var rawIdToken = idToken.idToken.rawIdToken;
    //         await runForcePairingsUpdateSync(airlineCode, rawIdToken);
    //         setSuccessMsg("Pairings update process started")
    //     }catch(e){
    //         setErrorMsg("Unexpected error. Please try again later")
    //     }
    //     setLoading(false)
    // };
    
    return <>
        <div className="card-container grid">
                <h3 className="card-title">Default Walking Times</h3>
                <div className="card-component">
                    {/* <Form 
                        render={(formRenderProps) => (
                            <FormElement>
                                <fieldset  className={'k-form-fieldset'}>
                                    <legend className={'k-form-legend'}>Manage default walking times</legend>

                                    
                                     <div className="k-form-buttons">
                                        <LoadingButton 
                                            onClick={onSubmit}
                                            loading={loading} 
                                            >
                                            Start Update Pairings Process
                                        </LoadingButton>
                                     </div>
                                </fieldset>
                            </FormElement>
                        )}

                    /> */}
                </div>
                <AlertSuccess
                    message={successMsg}
                    onClose={()=>setSuccessMsg(false)}
                 />
                <AlertFailure 
                    message={errorMsg}
                    onClose={() => setErrorMsg(false)}
                />
            </div>
    </>;



}