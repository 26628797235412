import { getAirlineLocks, setAirlineLockForPairingsExecutionHold, setAirlineUnlockForCrewSync, setAirlineUnlockForPairingsSync } from "./MicrohubApiProvider";
import { setCurrentAirlineLocks } from "./redux/Actions";

export const loadAirlineLocks = async (getIdToken, dispatch, airlineCode) => {
    var idToken = await getIdToken();
    var rawIdToken = idToken.idToken.rawIdToken;
    var locks = null;
    try{
        locks = await getAirlineLocks(airlineCode, rawIdToken );
    }catch(e){
        locks = {
            "pairingsSyncLock": false,
            "pairingsHeldLock": false
        }   
    }
    dispatch(setCurrentAirlineLocks(locks));
} 

export const updateAirlineLockForPairingsExecutionHold = async (getIdToken, airlineCode, lock) => {
    var idToken = await getIdToken();
    var rawIdToken = idToken.idToken.rawIdToken;
    await setAirlineLockForPairingsExecutionHold(airlineCode, lock, rawIdToken );
}

export const unlockAirlineForPairingsSync = async (getIdToken, airlineCode ) => {
    var idToken = await getIdToken();
    var rawIdToken = idToken.idToken.rawIdToken;
    await setAirlineUnlockForPairingsSync(airlineCode, rawIdToken );
}

export const unlockAirlineForCrewSync = async (getIdToken, airlineCode ) => {
    var idToken = await getIdToken();
    var rawIdToken = idToken.idToken.rawIdToken;
    await setAirlineUnlockForCrewSync(airlineCode, rawIdToken );
}