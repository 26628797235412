
import * as React from 'react';

import { CrewQueuesMonitor } from '../components/Crews/CrewQueuesMonitor';
import { FullCrewsImportForm } from '../components/Crews/FullCrewsImportForm';
import { HandleCrewsBufferForm } from '../components/Crews/HandleCrewsBufferForm';

import { SyncCrewForm } from '../components/Crews/SyncCrewForm';
import { DropCrewForm } from '../components/Crews/DropCrewForm';
import { AirlineFilter } from '../components/AirlineFilter';
import { ReleaseCrewSyncingLock } from '../components/Crews/ReleaseCrewSyncingLock';
import { LoadEmployeeDetailsFromFileForm } from '../components/Crews/LoadEmployeeDetailsFromFileForm';

const CrewsPage = () => {

    return (
        <div id="CrewsPage" className="crews-page main-content">
            <CrewQueuesMonitor />
            
            <AirlineFilter allowed={["AA"]}>
                <LoadEmployeeDetailsFromFileForm 
                    expectedFileFormat="csv"
                    />
            </AirlineFilter>
            
            <AirlineFilter allowed={["JL","HA"]}>
                <FullCrewsImportForm />
            </AirlineFilter>
            
            
            <AirlineFilter allowed={["JL"]}>
                <SyncCrewForm />
                <HandleCrewsBufferForm />
            </AirlineFilter>
            {false && <DropCrewForm />}

            <AirlineFilter allowed="HA">
                <ReleaseCrewSyncingLock />
            </AirlineFilter>
            
        </div>
    );
}

export default CrewsPage;

