import * as React from 'react';
import { Form, Field, FormElement } from '@progress/kendo-react-form';
import { FormUpload } from '../form-components';
import AlertFailure from '../AlertFailure';
import AlertSuccess from '../AlertSuccess';
import SubmitableLoadingButton from '../SubmitableLoadingButton';
import { AppContext } from '../../AppContext';
import { runPairingFasLoad } from '../../providers/MicrohubApiProvider';


export const AAMonthlyLoadManualFileForm = (props) => {

    const [errorMsg, setErrorMsg] = React.useState(false);
    const [successMsg, setSuccessMsg] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const { getIdToken, airlineCode } = React.useContext(AppContext);

    const onSubmit = async (data) => {
        console.log("Submitting", JSON.stringify(data, null, 4));
        if(!Array.isArray(data.bidFile) || data.bidFile.length === 0)
        {
            setErrorMsg("Please choose a FAS file to process");
            return;
        }
        setErrorMsg(false);
        setSuccessMsg(false)
        setLoading(true)
        try{
            var idToken = await getIdToken();
            var rawIdToken = idToken.idToken.rawIdToken;
            var filedata = data.bidFile.map((file) => file.getRawFile());
            var fasContent = await readFileAsync(filedata[0])
            await runPairingFasLoad(airlineCode, "INITIAL_LOAD", filedata[0].name, fasContent, rawIdToken );
            setSuccessMsg("Pairing sync completed")
        }catch(e){
            if(e.response && e.response.data){
                setErrorMsg(e.response.data);
            }else{
                setErrorMsg("Unexpected error. Please try again later");
            }
        }
        setLoading(false)
    };
    console.log("ENVIRONEMTN:",process.env.REACT_APP_ENV_NAME)
    return <>
        <div className="card-container grid">
                <h3 className="card-title">Monthly Load</h3>
                <div className="card-component">
                    <Form 
                        
                        onSubmit={onSubmit}
                        render={(formRenderProps) => (
                            <FormElement>
                                <fieldset  className={'k-form-fieldset'}>
                                    
                                    <legend className={'k-form-legend'}>Load pairings for a specific FAS file.</legend>
                                    

                                        <Field 
                                            id={'process-bid-file'}
                                            name={'bidFile'}
                                            label={'Bid File'}
                                            hint={'Choose a FAS file'}
                                            component={FormUpload}
                                        
                                        />
                                        <div className="k-form-buttons">
                                            <SubmitableLoadingButton
                                                loading={loading} 
                                                disabled={!formRenderProps.allowSubmit}>
                                                Load Pairings
                                            </SubmitableLoadingButton>
                                        </div>

                                </fieldset>
                            </FormElement>
                        )}
                    />
                </div>
                <AlertSuccess
                    message={successMsg}
                    onClose={()=>setSuccessMsg(false)}
                 />
                <AlertFailure 
                    message={errorMsg}
                    timeoutMs={10000}
                    onClose={() => setErrorMsg(false)}
                />
            </div>
    </>;



}

function readFileAsync(file){
    return new Promise( (res, err) => {
        let reader = new FileReader();
        reader.addEventListener("loadend", () => {
          res(reader.result) ;
        });
        reader.addEventListener("error", () => {
            err(reader.error);
        })
        reader.readAsText(file);
    });
}