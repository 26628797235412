import * as React from 'react';
import { Form, Field, FormElement } from '@progress/kendo-react-form';
import { FormFloatingDropDownList } from '../form-components';
import { CONSTANTS } from '../../constants';
import AlertFailure from '../AlertFailure';
import AlertSuccess from '../AlertSuccess';
import SubmitableLoadingButton from '../SubmitableLoadingButton';
import { AppContext } from '../../AppContext';
import { runPairingMonthlyLoad } from '../../providers/MicrohubApiProvider';
import { useSelector } from 'react-redux';
import { AirlineFilter } from '../AirlineFilter';


const requiredValidator = (value) => value ? "" : "Error: This field is required.";
const yearsAvailable = [
    (new Date().getFullYear() -1).toString(), 
    new Date().getFullYear().toString(), 
    (new Date().getFullYear() + 1).toString()
];
const monthsAvailable = [
    {id: "1", text: "January"},
    {id: "2", text: "Feburary"},
    {id: "3", text: "March"},
    {id: "4", text: "April"},
    {id: "5", text: "May"},
    {id: "6", text: "June"},
    {id: "7", text: "July"},
    {id: "8", text: "August"},
    {id: "9", text: "September"},
    {id: "10", text: "October"},
    {id: "11", text: "November"},
    {id: "12", text: "December"},
]

export const MonthlyLoadForm = (props) => {

    const [errorMsg, setErrorMsg] = React.useState(false);
    const [successMsg, setSuccessMsg] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const { getIdToken, airlineCode } = React.useContext(AppContext);


    var currentAirline = useSelector(state => state.currentAirline);
    var showBidMonth = (bp) => {
        var month = bp.bidPeriod.trim();
        return month.substr(4,2) + "/" + month.substr(0,4);
    }
    var activeBidMonths = currentAirline.bidPeriods.filter(bp => bp.activeBidInd).map(showBidMonth);
    //console.log(activeBidMonths.length)
    const onSubmit = async (data) => {
        console.log("Submitting", data);
        setErrorMsg(false);
        setSuccessMsg(false)
        setLoading(true)
        try{
            var idToken = await getIdToken();
            var rawIdToken = idToken.idToken.rawIdToken;
            await runPairingMonthlyLoad(airlineCode, data.year, data.month.id, data.eventType.id, rawIdToken);
            setSuccessMsg("Crew sync completed")
        }catch(e){
            if(e.response && e.response.data){
                setErrorMsg(e.response.data);
            }else{
                setErrorMsg("Unexpected error. Please try again later");
            }
        }
        setLoading(false)
    };
    var pairingEventTypes = CONSTANTS.PAIRINGS.EVENT_TYPES;
    if(CONSTANTS.PAIRINGS.BY_AIRLINE[airlineCode])
    {
        pairingEventTypes = pairingEventTypes.filter(type => CONSTANTS.PAIRINGS.BY_AIRLINE[airlineCode].indexOf(type.id) !== -1);
    }

    return <>
        <div className="card-container grid">
                <h3 className="card-title">Monthly Load</h3>
                <div className="card-component">
                    <Form 
                        onSubmit={onSubmit}
                        render={(formRenderProps) => (
                            <FormElement>
                                <fieldset  className={'k-form-fieldset'}>
                                    <legend className={'k-form-legend'}>Load pairings for a specific month.</legend>
                                    <Field
                                        id={'process-year'}
                                        name={'year'}
                                        label={'Year'}
                                        hint={'Hint: Choose a year'}
                                        component={FormFloatingDropDownList}
                                        data={yearsAvailable}
                                        validator={requiredValidator}
                                    />

                                    <Field
                                        id={'process-month'}
                                        name={'month'}
                                        label={'Month'}
                                        hint={'Hint: Choose a month'}
                                        component={FormFloatingDropDownList}
                                        data={monthsAvailable}
                                        validator={requiredValidator}
                                        textField="text"
                                        dataItemKey="id"
                                    />

                                    <Field
                                        id={'process-event-type'}
                                        name={'eventType'}
                                        label={'Event Type'}
                                        hint={'Hint: Choose an event type'}
                                        component={FormFloatingDropDownList}
                                        data={pairingEventTypes}
                                        validator={requiredValidator}
                                        textField="text"
                                        dataItemKey="id"
                                    />
                                    {activeBidMonths.length === 1 && <p className="text-success">Active bid month: {activeBidMonths[0]}</p>}
                                    {activeBidMonths.length > 1 && <p className="text-info">Active bid months: {activeBidMonths.join(", ")}</p>}
                                    {activeBidMonths.length === 0 && <p className="text-warning">There are no active bid months</p>}
                                    <AirlineFilter allowed={"HA"}>
                                        <>{currentAirline && currentAirline.locks && currentAirline.locks.pairingsSyncLock && <p className="text-warning">Another process is updating.</p>}</>
                                    </AirlineFilter>
                                    <div className="k-form-buttons">
                                        <SubmitableLoadingButton
                                            loading={loading} 
                                            disabled={!formRenderProps.allowSubmit}>
                                            Load Pairings
                                        </SubmitableLoadingButton>
                                    </div>
                                </fieldset>
                            </FormElement>
                        )}
                    />
                </div>
                <AlertSuccess
                    message={successMsg}
                    onClose={()=>setSuccessMsg(false)}
                 />
                <AlertFailure 
                    message={errorMsg}
                    timeoutMs={10000}
                    onClose={() => setErrorMsg(false)}
                />
            </div>
    </>;



}