import React from 'react'
import { Notification, NotificationGroup } from '@progress/kendo-react-notification';
import { Fade } from '@progress/kendo-react-animation';


export default function AlertSuccess({ message, onClose, timeoutMs=1500}){
    const [showAlertInternalFlag, setShowAlertInternalFlag] = React.useState(false);

    React.useEffect( () => {
        if(message && !showAlertInternalFlag){
            setShowAlertInternalFlag(true);
            setTimeout(() => {
                setShowAlertInternalFlag(false);
                if(message){
                    onClose();
                }
            }, timeoutMs);
        }
    }, [message, onClose, showAlertInternalFlag, setShowAlertInternalFlag, timeoutMs] );

    return(
        <NotificationGroup
            style={{
                right: 0,
                bottom: 0,
                alignItems: 'flex-start',
                flexWrap: 'wrap-reverse'
            }}
             >
            <Fade enter={true} exit={true}>
                {showAlertInternalFlag && message && <Notification
                    type={{ style: 'success', icon: true }}
                    closable={true}
                    onClose={onClose}
                >
                    <span>{message}</span>
                </Notification>}
            </Fade>
        </NotificationGroup>
    )
}




