import { getFeatureSettings } from "./MicrohubApiProvider";
import { setFeatureSettings } from "./redux/Actions";


export const loadFeatureSettingsAsync = async (getIdToken, dispatch ) => {
    var idToken = await getIdToken();
    var rawIdToken = idToken.idToken.rawIdToken;
    var featureSettings = await getFeatureSettings( rawIdToken );
    dispatch(setFeatureSettings(featureSettings));
    return featureSettings;
}