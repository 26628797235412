import * as React from 'react';
import { AppContext } from '../../AppContext';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { useSelector, useDispatch} from 'react-redux'
import { Button } from '@progress/kendo-react-buttons';
import { DatePicker,DateTimePicker} from '@progress/kendo-react-dateinputs';
import { DropDownList } from '@progress/kendo-react-dropdowns';
// import * as dateformat from 'dateformat';
import dateformat from "dateformat";
// import { format as dateformat} from 'date-fns';
import { MonthPicker } from '../CustomComponents/MonthPicker';
import AlertSuccess from '../AlertSuccess';
import AlertFailure from '../AlertFailure';
import { createBidPeriod, deleteBidPeriod, loadBidPeriods, updateBidPeriod } from '../../providers/BidPeriodsProvider';
import { CONSTANTS } from '../../constants';
import LoadingButton from '../LoadingButton';
import {Refresh as RefreshIcon} from '@mui/icons-material';


export const BidPeriodForm = (props) => {
    const { getIdToken, airlineCode } = React.useContext(AppContext);

    var currentAirline = useSelector(state => state.currentAirline);

    const [currentEdit, setCurrentEdit] = React.useState(false);
    const dispatch = useDispatch();

    const [editValues, setEditValues] = React.useState({});
    const [createRow, setCreateRow] = React.useState(null);

    const [errorMsg, setErrorMsg] = React.useState(false);
    const [successMsg, setSuccessMsg] = React.useState(false);

    function BidPeriodCell(props){
        var bp = props.dataItem.bidPeriod.trim().length === 6 ? (props.dataItem.bidPeriod.substring(0,4)+"-"+props.dataItem.bidPeriod.substring(4,6)) : "";
        var isEditing = currentEdit === props.dataItem.id;
        function onChange(e){
            var values = {...editValues};
            //var newValue = new Date(e.value.getTime() + e.value.getTimezoneOffset()*60*1000);
            values[props.field]=e.value;
            setEditValues(values);
        }
        return (<td>
            {isEditing  && <MonthPicker onChange={onChange} value={editValues[props.field]} />}
            {!isEditing && bp}
        </td>)
    }
    
    function BidPeriodDateCell(props){
        var value = props.dataItem[props.field].trim()
        var isEditing = currentEdit === props.dataItem.id;
        function onChange(e){
            var values = {...editValues};
            values[props.field]=e.value;
            setEditValues(values);
        }
        return (<td>
            {isEditing && <DatePicker onChange={onChange} value={editValues[props.field]}/>}
            {!isEditing && (value.length === 8 ? (value.substring(0,4)+"-"+value.substring(4,6)+"-"+value.substring(6,8)) : "")}
        </td>)
    }
    
    function BidPeriodBooleanCell(props){
        var value = props.dataItem[props.field]

        var isEditing = currentEdit === props.dataItem.id;
        var values = {true:"YES", false:"NO"};
        var listValues = ["YES", "NO"];
        function onChange(e){
            var values = {...editValues};
            values[props.field] = e.value==="YES";
            // console.log(`${props.field} ${editValues[props.field]} -> ${values[props.field]}`)
            setEditValues(values);
        }
        return (<td>
            {isEditing && <DropDownList data={listValues}  onChange={onChange} value={values[editValues[props.field]]}/>}
            {!isEditing && values[value] + ""}
        </td>)
    }
    
    function BidPeriodDateTimeCell(props){
        var value = props.dataItem[props.field]
        if(value[value.length - 1].toLowerCase() !== "z"){
            value = value + "Z"
        }
        // console.log(props.field+": values=" + value )
        var date = new Date(value);
        // console.log(props.field+ ": parsed:"+date.toISOString())
        var isEditing = currentEdit === props.dataItem.id && props.field !== 'postedDtTm';
        //  label = "yyyy-mm-dd'T'HH:MM:ss'Z'";
        var label = "UTC:m/d/yyyy, h:MM:ss TT"
        var showValue = (props.field === 'postedDtTm' && props.dataItem.id === -1) ? "N/A" : date && dateformat(date, label)//date.toLocaleString()
        function onChange(e){
            var values = {...editValues};
            var newValue = new Date(e.value.getTime() - e.value.getTimezoneOffset()*60*1000);
            values[props.field]=newValue;
            setEditValues(values);
        }
        var editValue = new Date(date.getTime() + date.getTimezoneOffset()*60*1000);
        return (<td>
            {isEditing && <>
                <DateTimePicker  onChange={onChange} value={editValue} />
            </>}
            {!isEditing && showValue}
        </td>)
    }

    const getEditValues = (values) => {
        var cutOffRaw = values.bidCutoffDtTm;
        if(cutOffRaw[cutOffRaw.length - 1].toLowerCase() !== "z"){
            cutOffRaw = cutOffRaw + "Z"
        }
        var editValues = {
            bidPeriod: new Date(values.bidPeriod.substring(0,4),values.bidPeriod.substring(4,6) - 1, 1),
            bidStart: new Date(values.bidStart.substring(0,4),values.bidStart.substring(4,6) - 1,values.bidStart.substring(6,8)),
            bidEnd: new Date(values.bidEnd.substring(0,4),values.bidEnd.substring(4,6) - 1,values.bidEnd.substring(6,8)),
            activeBidInd: values.activeBidInd,
            activeFeedInd: values.activeFeedInd,
            bidCutoffDtTm: new Date(cutOffRaw),
        };
        return editValues;
    };
    
    function EditCell(props){

        function editRow(){
            setCurrentEdit(props.dataItem.id);
            setEditValues(getEditValues(props.dataItem));
        }

        function cancelEdit(){
            if(currentEdit === -1){
                setCreateRow(null);
            }
            setCurrentEdit(false);
            
        }
        
        async function Save(){
            try{
                var newBidPeriod = {
                    ...props.dataItem, 
                    bidPeriod: dateformat(editValues.bidPeriod, 'yyyymm'),
                    bidStart: dateformat(editValues.bidStart, 'yyyymmdd'),
                    bidEnd: dateformat(editValues.bidEnd, 'yyyymmdd'),
                    activeBidInd: editValues.activeBidInd,
                    activeFeedInd: editValues.activeFeedInd,
                    bidCutoffDtTm: editValues.bidCutoffDtTm.toISOString(),
                };

                // console.log(currentAirline.bidPeriods)
                // console.log(`${newBidPeriod.id}: ${newBidPeriod.bidPeriod}`)
                var duplicatedBidPeriod = currentAirline.bidPeriods.filter(bp => bp.id !== newBidPeriod.id && bp.bidPeriod.trim() === newBidPeriod.bidPeriod);
                if(duplicatedBidPeriod.length > 0 ){
                    setErrorMsg(`There is already another entry for this bid period`);
                    return 
                }
                var overlapOnStart = currentAirline.bidPeriods.filter(bp =>  bp.id !== newBidPeriod.id && bp.bidStart <= newBidPeriod.bidStart && newBidPeriod.bidStart <= bp.bidEnd )
                if(overlapOnStart.length > 0 ){
                    setErrorMsg(`The bid start overlaps with period ${overlapOnStart[0].bidPeriod}`);
                    return 
                }

                var overlapOnEnd = currentAirline.bidPeriods.filter(bp =>  bp.id !== newBidPeriod.id && bp.bidStart <= newBidPeriod.bidEnd && newBidPeriod.bidStart <= bp.bidEnd )
                if(overlapOnEnd.length > 0 ){
                    setErrorMsg(`The bid end overlaps with period ${overlapOnEnd[0].bidPeriod}`);
                    return 
                }

                console.log("Saving bid period:", newBidPeriod)
                console.log(JSON.stringify(newBidPeriod, null, 4))
                
                if(currentEdit === -1){
                    setCreateRow(null);
                    await createBidPeriod(getIdToken, airlineCode, newBidPeriod, dispatch);
                    // dispatch(addCurrentAirlineBidPeriod(newBidPeriod));
                }else{
                    await updateBidPeriod(getIdToken, airlineCode, newBidPeriod, dispatch);
                    // dispatch(updateCurrentAirlineBidPeriod(newBidPeriod));
                }
                
                setCurrentEdit(false);
            }catch(e){  
                setErrorMsg(`Unexpected error: ${e.message}`);
            }
        }

        async function removeRow(){
            if(window.confirm(`Please confirm deleting period '${props.dataItem.bidPeriod.trim()}'`)){
                await deleteBidPeriod(getIdToken, airlineCode, props.dataItem.id, dispatch);
                // dispatch(deleteCurrentAirlineBidPeriod(props.dataItem));
            }
        }
        console.log(4);
        return (<td>
            <center>
                <Button icon="edit" onClick={editRow} disabled={currentEdit && currentEdit !== props.dataItem.id} style={{display: currentEdit === props.dataItem.id ? "none" : "inline-block", marginRight:20}}/>
                <Button icon="delete"  look="outline" onClick={removeRow} disabled={currentEdit && currentEdit !== props.dataItem.id} style={{display: currentEdit === props.dataItem.id ? "none" : "inline-block"}}/>
                <Button icon="check"  onClick={() => {Save();console.log("bye bye");return false;}}  style={{display: currentEdit !== props.dataItem.id ? "none" : "inline-block", marginRight:20}} />
                <Button icon="cancel"  onClick={cancelEdit}  style={{display: currentEdit !== props.dataItem.id ? "none" : "inline-block"}} />
            </center>
        </td>)
    }


    var bidPeriods = [...currentAirline.bidPeriods];
    bidPeriods.sort((a, b) => {
        if (a.bidPeriod > b.bidPeriod) {
          return -1;
        }
        if (a.bidPeriod < b.bidPeriod) {
          return 1;
        }
        return 0;
      })
      if(createRow !== null){
          bidPeriods = [createRow, ...bidPeriods];
      }

      
    function showCreateNewBidPeriod()
    {
        var maxPeriod = currentAirline.bidPeriods.reduce((ac, val) => Math.max(ac || 0, val.bidPeriod)) + "";
        console.log("maxPeriod", maxPeriod)
        var bidPeriod = new Date();
        if(maxPeriod){
            bidPeriod = new Date(maxPeriod.substring(0,4), maxPeriod.substring(4,6), 1);
        }
        var bidCutOff = dateformat(new Date( Date.UTC(bidPeriod.getFullYear(), bidPeriod.getMonth() + 1, 1, 0, 0, -1)), "UTC:yyyy-mm-dd'T'HH:MM:ss'Z'")
        console.log(bidCutOff)
        var newPeriod = {
            id:-1,
            bidPeriod: dateformat(bidPeriod, 'yyyymm'),
            bidStart: dateformat(bidPeriod, 'yyyymmdd'),
            bidEnd:  dateformat(new Date(bidPeriod.getFullYear(), bidPeriod.getMonth() + 1, 0), 'yyyymmdd'),
            activeBidInd: false,
            activeFeedInd: false,
            bidCutoffDtTm: bidCutOff,
            postedDtTm: new Date().toISOString(),
        };
        setCurrentEdit(-1);
        setEditValues(getEditValues(newPeriod));
        setCreateRow(newPeriod);
    }

    async function refreshBidsTable(){
        if(currentEdit) return;
        setCurrentEdit(-2);
        await loadBidPeriods(getIdToken, airlineCode, dispatch);
        setCurrentEdit(false);
    }


    return <>
        <div className="card-container grid">
                <h3 className="card-title">Bid Periods</h3>
                <div className="card-ranges">
                    {/* <Button icon="refresh" onClick={() => refreshBidsTable()} disabled={currentEdit !== false}></Button> */}
                    <LoadingButton color="secondary" onClick={refreshBidsTable} disabled={currentEdit !== false} hideContentOnLoading={true}><RefreshIcon /></LoadingButton>
                    
                </div>
                <div className="card-component">
                    <form className="k-form" onSubmit={(e) => e.preventDefault()}>
                        <fieldset  className={'k-form-fieldset'}>
                            <legend className={'k-form-legend'}>Manage bid periods for <b style={{fontSize:"1.2em", textTransform:"none"}}>{CONSTANTS.AIRLINES_BY_CODE[airlineCode]}</b></legend>
                                <div style={{textAlign:"right", paddingRight:20, paddingBottom:20}}>
                                    <Button color="primary" onClick={showCreateNewBidPeriod} disabled={currentEdit}>Create new bid period</Button>
                                </div>
                                
                                <Grid
                                    data={bidPeriods}
                                    dataItemKey="id">
                                    {/* <GridColumn field="id" title="Id"/>
                                    <GridColumn field="airlineCd" title="Airline" /> */}
                                    <GridColumn field="bidPeriod" title="Bid Period" cell={BidPeriodCell}  editable={false}/>
                                    <GridColumn field="bidStart" title="Start" cell={BidPeriodDateCell} />
                                    <GridColumn field="bidEnd" title="End" cell={BidPeriodDateCell} />
                                    <GridColumn field="activeBidInd" title="Active Bid" cell={BidPeriodBooleanCell} />
                                    <GridColumn field="activeFeedInd" title="Active Feed" cell={BidPeriodBooleanCell} />
                                    <GridColumn field="bidCutoffDtTm" title="Cut Off" cell={BidPeriodDateTimeCell} />
                                    <GridColumn field="postedDtTm" title="Last Updated" cell={BidPeriodDateTimeCell} />
                                    <GridColumn title="Actions" cell={EditCell}  editable={false}/>
                                </Grid>
                                <AlertSuccess
                                    message={successMsg}
                                    onClose={()=>setSuccessMsg(false)}
                                />
                                <AlertFailure 
                                    message={errorMsg}
                                    timeoutMs={5000}
                                    onClose={() => setErrorMsg(false)}
                                />
                        </fieldset>
                    </form>
                </div>
        </div>
    </>
}
