import { CONSTANTS } from "../constants";
import { getQueuesDetails } from "./MicrohubApiProvider"
import { setQueues, updateQueue } from "./redux/Actions";

export const loadQueuesState = async (getIdToken, dispatch) => {
    var idToken = await getIdToken();
    var rawIdToken = idToken.idToken.rawIdToken;
    var queuesDetails = await getQueuesDetails(CONSTANTS.QUEUES, rawIdToken );
    dispatch(setQueues(queuesDetails));
} 

export const updateQueueState = async (dispatch, newQueueState) => {
    dispatch(updateQueue(newQueueState));
} 

