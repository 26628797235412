import * as React from 'react';
import { Form, Field, FormElement } from '@progress/kendo-react-form';
import { FormFloatingDropDownList } from '../form-components';
import { getSubscriptionStatus } from '../../providers/MicrohubApiProvider';
import { AppContext } from '../../AppContext';
import SubmitableLoadingButton from '../SubmitableLoadingButton';
import AlertFailure from '../AlertFailure';
import { CONSTANTS } from '../../constants';
import { NumberIndicatorGroup } from '../CustomComponents/NumberIndicatorGroup';



const crewManagerSubscriptionEventTypes = CONSTANTS.CREWMANAGER.SUBSCRIPTIONS.EVENT_TYPES;



export const GetSubscriptionStatusForm = (props) => {
    const { getIdToken } = React.useContext(AppContext);
    const [loading, setLoading] = React.useState(false);
    const [errorMsg, setErrorMsg] = React.useState(false);
    const [unsubscribed, setUnsubscribed]= React.useState(false);
    // const [showSuccessAlert, setShowSuccessAlert] = React.useState(false);
    const [subscriptionStatus, setSubscriptionStatus] = React.useState(false)
    const onSubmit = async (data) => {
        setLoading(true);
        try{
            const eventName = data.eventType.id
            var idToken = await getIdToken();
            var rawIdToken = idToken.idToken.rawIdToken;
            const response = await getSubscriptionStatus(eventName, rawIdToken);
            const subscriptionStatus= response.subscriptionStatus;
            if(subscriptionStatus){
                setUnsubscribed(false);
                var newSubscriptionStatus = {
                    status: subscriptionStatus && subscriptionStatus.status && subscriptionStatus.status.status,
                    lastSucessfulDelivery: subscriptionStatus && subscriptionStatus.lastSuccessfulDeliveryTime && subscriptionStatus.lastSuccessfulDeliveryTime.lastSucessfulDelivery,
                    unsuccessfulDeliveryAttempts: subscriptionStatus && subscriptionStatus.unsuccessfulDeliveryAttempts && subscriptionStatus.unsuccessfulDeliveryAttempts.unsuccessfulDeliveryAttempts,
                    endpoint: subscriptionStatus && subscriptionStatus.deliveryMethod && subscriptionStatus.deliveryMethod.soapEndpoint && subscriptionStatus.deliveryMethod.soapEndpoint.url,
                    timeOutInMs: subscriptionStatus && subscriptionStatus.deliveryMethod && subscriptionStatus.deliveryMethod.soapEndpoint && subscriptionStatus.deliveryMethod.soapEndpoint.timeOutInMs,
                    subscribedOn: subscriptionStatus && subscriptionStatus.createDateTime && subscriptionStatus.createDateTime
                }
                setSubscriptionStatus(newSubscriptionStatus);
            }else{
                setUnsubscribed(true);
                setSubscriptionStatus(false);
            }
        }catch(e){
            setUnsubscribed(false);
            setSubscriptionStatus(false);
            setErrorMsg("Unexpected error. Please try again later")
        }
        setLoading(false);
    };


    const requiredValidator = (value) => (value && value.id) ? "" : "Error: This field is required.";
    return <>
        <div className="card-container grid">
                <h3 className="card-title">Get Subscription Status</h3>
                <div className="card-component">
                    <Form 
                        onSubmit={onSubmit}
                        render={(formRenderProps) => (
                            <FormElement>
                                <fieldset  className={'k-form-fieldset'}>
                                    <legend className={'k-form-legend'}>Get Subscription status for a Crew Manager Event</legend>

                                    
                                    <Field
                                        id={'process-event-type'}
                                        name={'eventType'}
                                        label={'Crew Manager Event'}
                                        hint={'Hint: Choose a Crew Manager Event'}
                                        component={FormFloatingDropDownList}
                                        data={crewManagerSubscriptionEventTypes}
                                        validator={requiredValidator}
                                        textField="text"
                                        dataItemKey="id" 
                                    />

                                     <div className="k-form-buttons">
                                        <SubmitableLoadingButton 
                                            loading={loading} 
                                            disabled={!formRenderProps.allowSubmit}>
                                        Get Status
                                        </SubmitableLoadingButton>
                                        
                                     </div>
                                </fieldset>
                            </FormElement>
                        )}
                    />
                    <NumberIndicatorGroup
                        data={formatSubscriptionStatus(subscriptionStatus, unsubscribed)}
                        // vertical={true}
                        wrap={true}
                        />
                    
                </div>
                <AlertFailure
                    message={errorMsg}
                    onClose={() => setErrorMsg(false)}
                />
            </div>
    </>;
}
function formatSubscriptionStatus(subscriptionStatus, unsubscribed){
    if(unsubscribed){
        return [
                {
                    title:"Subscription status",
                    value:"Not subscribed",
                    color:CONSTANTS.HTML_COLORS.textDanger,
                }
        ];
    }else if (subscriptionStatus){
        console.log("subscriptionStatus", subscriptionStatus)
        return [
            {
                title:"Subscription status",
                value:subscriptionStatus.status || "Status not available",
                color:CONSTANTS.HTML_COLORS.reactColor,
            },
            {
                title:"Subscribed on",
                value:formatISODateNiceIfAvailable(subscriptionStatus.subscribedOn.createDateTime, "No subscription is available"),
                color:CONSTANTS.HTML_COLORS.lemonTeam,
            },
            {
                title:"Last Sucessful Delivery",
                value:formatISODateNiceIfAvailable(subscriptionStatus.lastSucessfulDelivery, "No successfull delivery attemprs since last subscription"),
                color:CONSTANTS.HTML_COLORS.tealColor,
            },
            {
                title:"Last Unsuccessful Delivery Attempts",
                value:subscriptionStatus.unsuccessfulDeliveryAttempts || "0",
                color:CONSTANTS.HTML_COLORS.textDanger,
                units: "Attempts"
            },
            {
                title:"Endpoint timeout",
                value:subscriptionStatus.timeOutInMs.toLocaleString(),
                color:CONSTANTS.HTML_COLORS.backgroundColor,
                units: "ms"
            },
            {
                title:"Endpoint URL",
                value:subscriptionStatus.endpoint,
                color:CONSTANTS.HTML_COLORS.organicTeam,
            }
        ]
    }else{
        return [];
    }

}

const formatISODateNiceIfAvailable = (dateISO, msg) => {
    try{
        console.log(msg, dateISO)
        return (new Date(dateISO).toLocaleDateString() + " " + new Date(dateISO).toLocaleTimeString());
    }catch(e){
        console.log(e.message)
        return msg;
    }
}