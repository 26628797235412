import * as React from 'react';
import { QueuesPanel } from '../Queues/QueuesPanel';


export const TransportQueuesMonitor = (props) => {
    var queues = [
        {
            title:'Transportation Companies Sync',
            code:'providers-sync'
        }
    ]
    return (
        <QueuesPanel
            title={'Transportation Companies Queue'}
            queues={queues}
        />
    )
}