import * as React from 'react';
// import { Button } from '@progress/kendo-react-buttons';
import { AppContext } from '../../AppContext';
import { useDispatch, useSelector } from 'react-redux';
import { Form, FormElement } from '@progress/kendo-react-form';
import LoadingButton from '../LoadingButton';
import AlertSuccess from '../AlertSuccess';
import AlertFailure from '../AlertFailure';
import { loadAirlineLocks, updateAirlineLockForPairingsExecutionHold } from '../../providers/LockStateProvider';
import { setCurrentAirlineLocks } from '../../providers/redux/Actions';
import {Refresh as RefreshIcon} from '@mui/icons-material';

export const HoldPairingSyncLockForm = () => {
    const { getIdToken, airlineCode } = React.useContext(AppContext);
    const dispatch = useDispatch();
    const [errorMsg, setErrorMsg] = React.useState(false);
    const [successMsg, setSuccessMsg] = React.useState(false);

    const currentAirline = useSelector( state => state.currentAirline && state.currentAirline) ;

    const isHoldingPairings = (currentAirline && currentAirline.locks && currentAirline.locks.pairingsHeldLock) || false;

    const refreshLocks = async () => {
        await loadAirlineLocks(getIdToken, dispatch, airlineCode);
    }

    const onSubmit = async () => {
        try{
            setSuccessMsg(false);
            await updateAirlineLockForPairingsExecutionHold(getIdToken, airlineCode, !isHoldingPairings);
            dispatch(setCurrentAirlineLocks({...currentAirline.locks, pairingsHeldLock: !isHoldingPairings}));
            setSuccessMsg(isHoldingPairings ? "Pairings sync proces is disabled" : "Pairings sync proces is active");
        }catch(e){
            setErrorMsg("Unexpected error. Please try again later")
        }
    }

    const stateTitle = !isHoldingPairings ? "Pairing update process is enabled." : "Pairing update process is disabled."
    const buttonLabel = isHoldingPairings ? "Enable process" : "Disable process"
    return (
        <div id="SubscriptionsPage" className="subscriptions-page main-content">
            <div className="card-container grid">
                <h3 className="card-title">Pairing update status</h3>
                <div className="card-ranges">
                    <LoadingButton onClick={refreshLocks}><RefreshIcon/></LoadingButton>
                </div>
                <div className="card-component">
                    <Form 
                        // onSubmit={onSubmit}
                        render={(formRenderProps) => (
                            <FormElement>
                                <fieldset  className={'k-form-fieldset'}>
                                    <legend className={'k-form-legend'}>Prevent the scheduled pairing update process from starting. It will not stop a running execution.</legend>
                                    <h4>{stateTitle}</h4>
                                    <div className="k-form-buttons">
                                        <LoadingButton onClick={onSubmit}>{buttonLabel}</LoadingButton>
                                    </div>
                                </fieldset>
                            </FormElement>

                        )}

                    />
                </div>
                <AlertSuccess
                    message={successMsg}
                    onClose={()=>setSuccessMsg(false)}
                 />
                <AlertFailure
                    message={errorMsg}
                    onClose={() => setErrorMsg(false)}
                />
            </div>
        </div>
    );

}