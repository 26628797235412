import * as React from 'react';


const numberIndicatorStyle = {
    container:{
        display: 'flex', 
        flexDirection: 'row',
        marginRight: 25, 
        marginBottom: 10,
        marginTop: 10
    },
    sideBar:{
        width: 5,
        marginRight: 5
    },
    textContainer:{
    },
    title:{
        fontSize: 16,
        lineHeight: "10px", 
        display: 'inline-block'
    },
    valueContainer:{
        lineHeight: "20px"
    },
    numberLabel:{
        fontSize: 20,
        display: 'inline', 
        fontWeight: 500
    },
    unitsLabel:{
        fontSize: 10, 
        display: 'inline', 
        textTransform: 'uppercase', 
        fontWeight: 700
    }
}

export const NumberIndicator = ({title="", value="", units=false, color="#000000"}) => {
    return (
        <div style={numberIndicatorStyle.container}>
                <div  style={{...numberIndicatorStyle.sideBar, backgroundColor:color}}></div>
                <div style={numberIndicatorStyle.textContainer}>
                    <div  style={numberIndicatorStyle.title}>{title}</div>
                    <div style={numberIndicatorStyle.valueContainer}>
                        <div  style={numberIndicatorStyle.numberLabel}>{value}</div>
                        {units && (<div style={numberIndicatorStyle.unitsLabel}>&nbsp;{units}</div>)}
                    </div>
                </div>
            </div>
    );
}



