import * as React from 'react';
import { Form, Field, FormElement } from '@progress/kendo-react-form';
import { FormFloatingDropDownList } from '../form-components';
import AlertFailure from '../AlertFailure';
import AlertSuccess from '../AlertSuccess';
import SubmitableLoadingButton from '../SubmitableLoadingButton';
import { AppContext } from '../../AppContext';
import { getFileFromBlobStorage, runAAFasExportHotelFiles } from '../../providers/MicrohubApiProvider';
import { useDispatch, useSelector } from 'react-redux';
import { loadBidPeriods } from '../../providers/BidPeriodsProvider';
import { AsyncHttpMonitor } from '../AsyncHttpMonitor';
import { Button } from '@progress/kendo-react-buttons';
import LoadingButton from '../LoadingButton';
import {Refresh as RefreshIcon} from '@mui/icons-material';
import ClearIcon from '@mui/icons-material/Clear';
import * as fileDownload from 'js-file-download';

const requiredValidator = (value) => value ? "" : "Error: This field is required.";

const formatBidPeriod = value => `${value.substring(0,4)}-${value.substring(4,6)}`
const formatBidPeriodDate = value => `${value.substring(0,4)}-${value.substring(4,6)}-${value.substring(6,8)}`

const sortBidPeriods = (a,b) => {
    if(a.bidPeriod.trim() < b.bidPeriod.trim()) return 1;
    if(a.bidPeriod.trim() > b.bidPeriod.trim()) return -1;
    if(a.bidPeriod.trim() === b.bidPeriod.trim()) return 0;
}


export const AAExportFilesForm = (props) => 
{
    const [errorMsg, setErrorMsg] = React.useState(false);
    const [successMsg, setSuccessMsg] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [statusUrl, setStatusUrl] = React.useState(false);
    const { getIdToken, airlineCode } = React.useContext(AppContext);
    const [fasExportPath, setFasExportPath] = React.useState(false);
    const dispatch = useDispatch();

    React.useEffect( () => {

        loadBidPeriods(getIdToken, airlineCode, dispatch);

    }, [getIdToken, airlineCode, dispatch]);

    var currentAirline = useSelector(state => state.currentAirline);
    // var showBidMonth = (bp) => {
    //     var month = bp.bidPeriod.trim();
    //     return month.substr(4,2) + "/" + month.substr(0,4);
    // }
    const onSubmit = async (data) => {
        console.log("Submitting", data);
        setErrorMsg(false);
        setSuccessMsg(false)
        setLoading(true)
        try{
            var idToken = await getIdToken();
            var rawIdToken = idToken.idToken.rawIdToken;
            var response = await runAAFasExportHotelFiles(airlineCode, data.bidPeriod.id, rawIdToken);
            setStatusUrl(response.statusQueryGetUri);
            // performHttpGetOperationOnMicrohub
            setSuccessMsg("Files are being created. Please wait.")
        }catch(e){
            if(e.response && e.response.data){
                setErrorMsg(e.response.data);
            }else{
                setErrorMsg("Unexpected error. Please try again later");
            }
        }
        setLoading(false)
    };

    const downloadFile = async () => {
        if(fasExportPath){
            
            var [container, filePath] = fasExportPath.split(":");
            if(typeof(container) === "string" && typeof(filePath) == "string" ){
                var idToken = await getIdToken();
                var rawIdToken = idToken.idToken.rawIdToken;
                var response = await getFileFromBlobStorage(container, filePath, rawIdToken);
                var pathParts = filePath.split("/");
                var filename = pathParts.pop();
                fileDownload(response, filename)
                
            }
            
        }
        return false;
        
    }

    const onFilesCreated = async (path, err) => {
        setStatusUrl(false);
        if(path){
            setSuccessMsg("FAS Export files created!")
            setFasExportPath(path);
            downloadFile();
        }else if(err){
            setErrorMsg("Unexpected error generating export files: " + err.message);
            setFasExportPath(false);
        }else{
            setFasExportPath(false);
        }
    };
    const refreshBidPeriods = async () => {
        await loadBidPeriods(getIdToken, airlineCode, dispatch);
    };
    var availableBidPeriods = currentAirline.bidPeriods.sort(sortBidPeriods).map(bp => ({id:bp.id, text:`${formatBidPeriod(bp.bidPeriod)}  (${formatBidPeriodDate(bp.bidStart)} - ${formatBidPeriodDate(bp.bidEnd)})` }));
    console.log("Active bid period", availableBidPeriods);
    // var bidPeriodsAvailable = activeBid
    console.log("ENVIRONEMTN:",process.env.REACT_APP_ENV_NAME)
    return <>
        <div className="card-container grid">
                <h3 className="card-title">Export files</h3>
                <div className="card-component">
                    <Form 
                        onSubmit={onSubmit}
                        render={(formRenderProps) => (
                            <FormElement>
                                <fieldset  className={'k-form-fieldset'}>
                                    
                                    <legend className={'k-form-legend'}>Create export files for FAS with providers for a given period.</legend>
                                    
                                    {availableBidPeriods.length === 0 && <>
                                        <p className="text-warning">There are no bid periods loaded. 
                                        <LoadingButton onClick={refreshBidPeriods}  hideContentOnLoading={true}>
                                            <RefreshIcon/></LoadingButton>
                                        </p>
                                    </>}
                                    {availableBidPeriods.length > 0 && <div>
                                        <Field
                                            id={'process-bid-period'}
                                            name={'bidPeriod'}
                                            label={'Bid Period'}
                                            hint={'Hint: Choose a bid period'}
                                            component={FormFloatingDropDownList}
                                            data={availableBidPeriods}
                                            validator={requiredValidator}
                                            textField="text"
                                            dataItemKey="id"
                                        />
                                        <div className="k-form-buttons">
                                            <SubmitableLoadingButton
                                                loading={loading} 
                                                disabled={!formRenderProps.allowSubmit}>
                                                Create Files
                                            </SubmitableLoadingButton>
                                        </div>
                                    </div>}


                                </fieldset>
                            </FormElement>
                        )}
                    />
                    <AsyncHttpMonitor
                        refreshUrl={statusUrl}
                        onComplete={onFilesCreated}
                    />
                    {fasExportPath && (<>
                        <div className="file-download-container"
                            style={{display:"inline-block"}}
                        > You can now download your file, please <a href="#" onClick={downloadFile} target="_blank" className="clickeable-link">click here</a>.
                        <Button fillMode="flat" style={{marginLeft:15}} onClick={() => setFasExportPath(false)}><ClearIcon fontSize="small" style={{marginBottom:-5, }}/></Button>
                        </div>
                    </>)}
                </div>
                <AlertSuccess
                    message={successMsg}
                    onClose={()=>setSuccessMsg(false)}
                 />
                <AlertFailure 
                    message={errorMsg}
                    timeoutMs={10000}
                    onClose={() => setErrorMsg(false)}
                />
            </div>
    </>;

}





