
import * as React from 'react';

import { DropPairingForm } from '../components/Pairings/DropPairingForm';
import { HandlePairingsBufferForm } from '../components/Pairings/HandlePairingsBufferForm';
import { MonthlyLoadForm } from '../components/Pairings/MonthlyLoadForm';
import { MonthlyLoadOnBidPeriodForm } from '../components/Pairings/MonthlyLoadOnBidPeriodForm';
import { PairingQueuesMonitor } from '../components/Pairings/PairingQueuesMonitor';
import { SyncPairingForm } from '../components/Pairings/SyncPairingForm';
import {AirlineFilter} from '../components/AirlineFilter';
import { UpdatePairingsProcessForm } from '../components/Pairings/UpdatePairingsProcessForm';
import { HoldPairingSyncLockForm } from '../components/Pairings/HoldPairingSyncLockForm';
import { ReleasePairingSyncLockForm } from '../components/Pairings/ReleasePairingSyncingLock';
import { AAMonthlyLoadManualFileForm } from '../components/Pairings/AAMonthlyLoadManualFileForm';
import { AAExportFilesForm } from '../components/Pairings/AAExportFilesForm';
import { ReprocessPairingForm } from '../components/Pairings/ReprocessPairingForm';



const PairingsPage = () => {
    

    return (
        <div id="PairingsPage" className="pairings-page main-content">
            <PairingQueuesMonitor />

            <AirlineFilter allowed="AA">
                <AAMonthlyLoadManualFileForm />
                <AAExportFilesForm />
                <ReprocessPairingForm />
            </AirlineFilter>

            <AirlineFilter allowed="JL">
                <MonthlyLoadForm/>
            </AirlineFilter>    
            
            <AirlineFilter allowed="HA">
                <MonthlyLoadOnBidPeriodForm />
            </AirlineFilter>    
            
            <AirlineFilter allowed="JL">
                <SyncPairingForm/>
            </AirlineFilter>
            
            <AirlineFilter allowed="HA">
                <UpdatePairingsProcessForm/>
            </AirlineFilter>
                
            <AirlineFilter allowed="JL">
                { false && <DropPairingForm/>}
            </AirlineFilter>
            
            <AirlineFilter allowed={["JL", "NK"]}>
                <HandlePairingsBufferForm />
            </AirlineFilter>
            
            <AirlineFilter allowed="HA">
                <HoldPairingSyncLockForm/>
                <ReleasePairingSyncLockForm />
            </AirlineFilter>
            
        </div>
    );
}

export default PairingsPage;

