import * as React from 'react';
import { Form,  FormElement } from '@progress/kendo-react-form';
import { AppContext } from '../../AppContext';
import AlertSuccess from '../AlertSuccess';
import AlertFailure from '../AlertFailure';
import { runForcePairingsUpdateSync } from '../../providers/MicrohubApiProvider';
import LoadingButton from '../LoadingButton';
import { useDispatch, useSelector } from 'react-redux';
import { AirlineFilter } from '../AirlineFilter';
import { loadAirlineLocks } from '../../providers/LockStateProvider';


export const UpdatePairingsProcessForm = (props) => {
    const [errorMsg, setErrorMsg] = React.useState(false);
    const [successMsg, setSuccessMsg] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const { getIdToken, airlineCode } = React.useContext(AppContext);
    const dispatch = useDispatch();
    var currentAirline = useSelector(state => state.currentAirline);
    console.log(currentAirline.bidPeriods);
    var showFeedMonth = (bp) => {
        var month = bp.bidPeriod.trim();
        return month.substr(4,2) + "/" + month.substr(0,4);
    }
    var activeFeedMonths = currentAirline.bidPeriods.filter(bp => bp.activeFeedInd).map(showFeedMonth);


    const onSubmit = async () => {
        console.log("Submitting");
        setLoading(true)
        try{
            var idToken = await getIdToken();
            var rawIdToken = idToken.idToken.rawIdToken;
            await runForcePairingsUpdateSync(airlineCode, rawIdToken);
            if(airlineCode === "HA"){
                await loadAirlineLocks(getIdToken, dispatch, airlineCode);
            }
            setSuccessMsg("Pairings update process started")
        }catch(e){
            setErrorMsg("Unexpected error. Please try again later")
        }
        setLoading(false)
    };
    
    return <>
        <div className="card-container grid">
                <h3 className="card-title">Update Pairing</h3>
                <div className="card-component">
                    <Form 
                        render={(formRenderProps) => (
                            <FormElement>
                                <fieldset  className={'k-form-fieldset'}>
                                    <legend className={'k-form-legend'}>Starts a pairings update process</legend>

                                    {activeFeedMonths.length === 1 && <p className="text-success">Active feed month: {activeFeedMonths[0]}</p>}
                                    {activeFeedMonths.length > 1 && <p className="text-success">Active feed months: {activeFeedMonths.join(", ")}</p>}
                                    {activeFeedMonths.length === 0 && <p className="text-warning">There are no active feed months</p>}
                                    <AirlineFilter allowed={"HA"}>
                                        {currentAirline && currentAirline.locks && currentAirline.locks.pairingsSyncLock && <p className="text-warning">Another process is updating.</p>}
                                        {currentAirline && currentAirline.locks && currentAirline.locks.pairingsHeldLock && <p className="text-warning">Pairing update is held.</p>}
                                    </AirlineFilter>
                                     <div className="k-form-buttons">
                                        <LoadingButton 
                                            onClick={onSubmit}
                                            loading={loading} 
                                            >
                                            Start Update Pairings Process
                                        </LoadingButton>
                                     </div>
                                </fieldset>
                            </FormElement>
                        )}

                    />
                </div>
                <AlertSuccess
                    message={successMsg}
                    onClose={()=>setSuccessMsg(false)}
                 />
                <AlertFailure 
                    message={errorMsg}
                    onClose={() => setErrorMsg(false)}
                />
            </div>
    </>;



}