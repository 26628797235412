import * as React from 'react';
import { Form, Field, FormElement } from '@progress/kendo-react-form';
import {  FormFloatingDropDownList } from '../form-components';
import SubmitableLoadingButton from '../SubmitableLoadingButton';
import AlertSuccess from '../AlertSuccess';
import AlertFailure from '../AlertFailure';
import { runCrewInitialImport } from '../../providers/MicrohubApiProvider';
import { AppContext } from '../../AppContext';
import { CONSTANTS } from '../../constants';
import { AirlineFilter } from '../AirlineFilter';
import { useSelector } from 'react-redux';


const requiredValidator = (value) => value ? "" : "Error: This field is required.";

const crewEventTypes = CONSTANTS.CREWS.EVENT_TYPES;

export const FullCrewsImportForm = (props) => {

    const [errorMsg, setErrorMsg] = React.useState(false);
    const [successMsg, setSuccessMsg] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const { getIdToken, airlineCode} = React.useContext(AppContext);

    const currentAirline = useSelector( state => state.currentAirline && state.currentAirline) ;

    var crewPositions = airlineCode === "JL" ? CONSTANTS.CREWS.POSITIONS.JAL : [];
    const ranksAvailable = [{id:"", text:"Import all crews"}, ...crewPositions.map(pos => ({ id: pos.trim(), text: pos.trim()}))];


    const onSubmit = async (data) => {
        console.log("Submitting", data);
        setLoading(true)
        try{
            var idToken = await getIdToken();
            var rawIdToken = idToken.idToken.rawIdToken;
            var rankId = (data && data.rank && data.rank.id) || "";
            var changeType = data.eventType.id;
            await runCrewInitialImport(airlineCode, rankId, changeType, rawIdToken);
            setSuccessMsg("Crew sync completed")
        }catch(e){
            setErrorMsg("Unexpected error. Please try again later")
        }
        setLoading(false)
    };
    
    

    return <>
        <div className="card-container grid">
                <h3 className="card-title">Full Crew Load</h3>
                <div className="card-component">
                    {/* <AirlineFilter allowed={["JL"]}> */}
                        <Form 
                            onSubmit={onSubmit}
                            render={(formRenderProps) => (
                                <FormElement>
                                    <fieldset  className={'k-form-fieldset'}>
                                        <legend className={'k-form-legend'}>Import all crews</legend>

                                        <Field
                                            id={'process-event-type'}
                                            name={'eventType'}
                                            label={'Event Type'}
                                            hint={'Hint: Choose an event type'}
                                            component={FormFloatingDropDownList}
                                            data={crewEventTypes}
                                            validator={requiredValidator}
                                            textField="text"
                                            dataItemKey="id"
                                        />
                                        <AirlineFilter allowed={["JL"]}>
                                            <Field
                                                id={'process-rank'}
                                                name={'rank'}
                                                label={'Import all crews or a single rank'}
                                                hint={'Hint: Choose a rank'}
                                                component={FormFloatingDropDownList}
                                                // component={FormDropDownList}
                                                data={ranksAvailable}
                                                textField="text"
                                                dataItemKey="id"
                                                // defaultValue={ranksAvailable[0]}
                                            />
                                        </AirlineFilter>
                                        <AirlineFilter allowed="HA">
                                            <>{currentAirline && currentAirline.locks && currentAirline.locks.crewsSyncLock && <p className="text-warning">Another process is updating.</p>}</>
                                        </AirlineFilter>
                                        <div className="k-form-buttons">
                                            <SubmitableLoadingButton
                                                loading={loading}>
                                                Import Crew Members
                                            </SubmitableLoadingButton>
                                        </div>
                                    </fieldset>
                                </FormElement>
                            )}
                        />
                    {/* </AirlineFilter>
                    <AirlineFilter allowed={['NK', 'HA']}>
                        <Form 
                                render={(formRenderProps) => (
                                    <FormElement>
                                        <fieldset  className={'k-form-fieldset'}>
                                            <legend className={'k-form-legend'}>Import crews</legend>

                                            <div className="k-form-buttons">
                                                <LoadingButton
                                                    onClick={onSubmit}
                                                    loading={loading} 
                                                    >
                                                    Import Crew Memebers
                                                </LoadingButton>
                                            </div>
                                        </fieldset>
                                    </FormElement>
                                )}
                        />
                    </AirlineFilter> */}
                </div>
                <AlertSuccess
                    message={successMsg}
                    onClose={()=>setSuccessMsg(false)}
                 />
                <AlertFailure 
                    message={errorMsg}
                    onClose={() => setErrorMsg(false)}
                />
            </div>
    </>;



}